@import "scss/variables";

.ExternalOrderPaymentModal {
  .DoNotShowAgainLabel {
    margin-top: $spacing-sm;

    :global(.MuiFormControlLabel-label) {
      font-weight: 700;
    }
  }
}
