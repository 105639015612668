@import "scss/variables";

.MarkOrderedModal {
  .ModalContent {
    margin-top: $spacing-lg;
  }

  .Form {
    margin-top: $spacing-lg;
  }

  .Submit {
    display: none;
  }
}
