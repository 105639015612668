@import "scss/variables";

.PurchaseOrder {
  padding: $spacing-md;
  background: get-color("grey-50") !important;
}

.Wrapper {
  flex-direction: column;
  @include md {
    flex-direction: row;
  }
}

.OrderInformation {
  flex-flow: column nowrap;

  @include md {
    flex: 1 1 50%;
  }
  @include xl {
    flex-flow: row wrap;
    align-items: center;
    .SupplierInfo {
      margin-right: 1em;
    }
    .OrderNumber {
      flex-basis: 100%;
      margin: $spacing-sm 0 0;
      order: 3;
    }
  }
}

.SupplierInfo {
  white-space: nowrap;
  color: get-color("grey-700");
  > * {
    display: inline-block;
    vertical-align: middle;
  }

  .SupplierName {
    margin-left: $spacing-sm;
    font-weight: 700;
  }
}

.OrderNumber {
  //margin-left: 42px; // TODO: Bulk orders checkbox
  margin: $spacing-sm 0 $spacing-md;
  color: get-color("grey-600");
  flex: 1 0 100%;
}

.Statuses {
  margin-bottom: - $spacing-sm;
  > * {
    margin-bottom: $spacing-sm;
    margin-right: $spacing-sm;
  }
}

.Errors {
  margin: $spacing-md 0 0;
  width: 100%;
  > * + * {
    margin-top: $spacing-md;
  }
  @include md {
    order: 3;
  }
}

.OrderTable {
  width: 100%;
  margin-top: $spacing-md;
  @include md {
    order: 4;
  }
}

.Actions {
  @include md {
    order: 2;
  }
}
