@import "scss/variablesV3";

.SyncListItemStatusBadge {

}

.Badge {
  background: get-color("error7");
  color: get-color("grey0");
}
