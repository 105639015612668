$palette: (
  "black": #000000,
  "white": #FFFFFF,
  "primary-100": #E8ECFD,
  "primary-400": #706FD2,
  "primary-500": #4B4AC6,
  "primary-600": #3535A7,
  "primary-700": #263D91,
  "primary-800": #0E2139,
  "primary-900": #0B1A2D,
  "secondary-400": #AA69E3,
  "secondary-500": #923FDB,
  "secondary-600": #7824C1,
  "error-50": #F8D8D8,
  "error-500": #F5696A,
  "error-600": #C45455,
  "error-700": #933F40,
  "success-50": #E3F2EE,
  "success-500": #78C3AF,
  "success-600": #42947E,
  "warning-50": #FFF4D1,
  "warning-400": #F5CC55,
  "warning-500": #FFC107,
  "warning-600": #D19D00,
  "accent-1": #9B5983,
  "accent-2": #2B3A4D,
  "blue-grey-3": #97ABC3,
  "blue-grey-4": #6E85A6,
  "blue-grey-5": #617BD6,
  "grey-50": #F3F4F5,
  "grey-100": #E7E9EC,
  "grey-200": #CFD3D8,
  "grey-300": #B6BCC5,
  "grey-400": #9EA6B1,
  "grey-500": #86909E,
  "grey-600": #6B737E,
  "grey-700": #50565F,
  "grey-800": #363A3F,
  "grey-900": #1B1D20,
);

@function get-color($color) {
  @return map-get($palette, $color)
}

@import "common";
