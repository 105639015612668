@import "scss/variablesV3";

.WixPrintOnDemandBanner {
  position: relative;
  background-color: #FDFBFB;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: $spacing-sm;
  padding: $spacing-sm 0 0;

  :global(.MuiCardMedia-root) {
    width: 100%;
    max-width: 294px;
    position: relative;
    aspect-ratio: 816 / 389;
  }

  :global(.MuiCardContent-root) {
    max-width: 430px;
    text-align: center;
  }

  @include md {
    flex-flow: row nowrap;
    gap: 2 * $spacing-paper;
    padding: $spacing-md $spacing-lg;
    padding: $spacing-md;

    :global(.MuiCardMedia-root) {
      width: 50%;
      max-width: none;
    }

    :global(.MuiCardContent-root) {
      width: 50%;
      max-width: none;
      text-align: start;
    }
  }
}

.DismissButton {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
}

.WixPlusPrintful {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 auto $spacing-md;
  > svg:first-child { height: 16px; }
  > svg:last-child { height: 18px; }
  i {
    display: inline-block;
    border-radius: 50%;
    box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.12),
                0px 6px 10px 0px rgba(0, 0, 0, 0.14),
                0px 3px 5px -1px rgba(0, 0, 0, 0.2);
    font-weight: 200;
    padding: 4px;
    margin: -1px .4em 0 .8em;
    svg {
      display: block;
      width: 7px;
      height: 7px;
      fill: get-color("secondary100");
    }
  }
}
