@import "scss/variablesV3";
@import "scss/mixins";

.CardContent {
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-flow: column nowrap;
  @include desktop { flex-flow: row nowrap; }
}

.Description {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-md ($spacing-paper / 2);


  @include sm {
    padding: $spacing-md;
  }

  @include desktop {
    width: 240px;
    padding: $spacing-md $spacing-paper;
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  :global(.MuiTypography-paragraph) {
    font-size: $font-size-body3;
    margin: $spacing-sm 0 $spacing-md;
  }
}

.Button {
  margin-top: auto;
  border: none !important;
  background-color: get-color("white");
  &:hover {
    background-color: get-color("primary10");
  }
}

.ProductCarousel {

  .ScrollButton {
    align-self: stretch;
    min-width: 0;
    padding: 0;
    opacity: 0;
    color: get-color("primary15");
    transition: opacity $transition-default ease-in-out,
                visibility $transition-default ease-in-out,
                color $transition-default ease-in-out,
                background-color $transition-default ease-in-out;
    visibility: hidden;
  }

  &:hover .ScrollButton {
    opacity: 1;
    visibility: visible;
  }

  .ScrollButton:hover {
    color: get-color("primary20");
  }
}

.CarouselItem {
  flex: 1 1 1%;
  :global(.MuiCard-root) {
    position: relative;
    &:before {
      content: "";
      display: block;
      height: 0;
      padding-top: 100%;
    }
  }
  a, :global(.MuiCardMedia-root), :global(.MuiSkeleton-root) {
    @include fill-parent;
  }
  :global(.MuiSkeleton-root) {
    pointer-events: none;
  }
  :global(.MuiCardMedia-root) {
    outline: 1px solid rgba(get-color("grey15"), $color-alpha-low);
    outline-offset: -1px;
    border-radius: 4px;
  }
}
