@import "scss/variables";

.UserLoginInfoSettings {

}

.UsernameHelper {
  margin-top: $spacing-sm;
  font-size: 14px !important;
}

.Errors {
  margin-bottom: $spacing-lg;
}
