@import "scss/variablesV3";

.ImportListProductGeneralTab {
  padding: 32px 24px;
  height: 400px;
  overflow: auto;
}

.Chip {
  margin-right: $spacing-xs;
}
