@import "scss/variablesV3";

.SearchField {
  &.small {
    :global(.MuiInputBase-root) {
      height: 30px;
    }

    :global(.MuiOutlinedInput-adornedStart) {
      padding-left: 0;
    }

    :global(.MuiIconButton-root) {
      padding: 6px;
    }

    :global(.MuiInputBase-input) {
      font-size: 14px;
    }
  }
}

.Search {

}

.Input {
  background: get-color("white");
  height: 56px;

  :global(.MuiInputAdornment-positionStart) {
    margin-right: 0;
  }

  :global(.MuiInputAdornment-positionEnd) {
    margin-right: -$spacing-sm;
  }
}
