@import "scss/variables";

.Root {
}

.Avatar {
  padding-left: $spacing-xs;
  :global(.MuiAvatar-root) {
    width: 32px;
    height: 32px;
  }
  :global(.MuiSvgIcon-root) {
    width: 20px;
    height: 20px;
  }
}

.ActionIcon {
  align-self: center;
  margin-left: $spacing-sm;
  color: rgba(get-color("black"), $color-alpha-medium);
}

.Root-success {
  .Avatar {
    :global(.MuiAvatar-root) {
      background-color: get-color("success-600");
    }
  }
}

.Root-error {
  .Avatar {
    :global(.MuiAvatar-root) {
      background-color: get-color("error-600");
    }
  }
}
