@import "scss/variablesV3";

.ChipList {
 margin: -2px;
}

.Chip {
  margin: 2px;
  font-weight: 500;
}

.ClearAllButton {
  height: 24px;
  margin: 2px;
  padding: 0;
  font-weight: 600;
}
