@import "scss/variablesV3";

.SortFilter {
  color: get-color("grey40");

  .Icon {
    color: get-color("primary40");
    width: 26px;
    height: 26px;
  }

  :global(.MuiButton-startIcon) {
    margin-right: 2px;
  }
}
