@import "scss/variablesV3";

.MarketplaceCategoryPicker {

}

.Paper {
  height: 100%;
  overflow: hidden;

  @include lg {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 50.5vh;
  }
}

.List {
  height: 100%;
  overflow: hidden;
  background-color: white;
}
