@import "scss/variablesV3";

.PlatformSelect {
  margin: $spacing-md 0;
}

.RadioTileGroupLabel {
  font-size: $font-size-body2;
  margin-bottom: $spacing-md;
}

.RadioTileGroup {
  gap: $spacing-md;
}

.RadioTileTooltipHelper {
  font-size: 20px;
}
