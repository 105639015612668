$palette: (
   "white": #FFFFFF,
   "black": #000000,
   "textPrimary": #000000de,
   "textSecondary": #0000008a,
   "primary-100": #E6EAF9,
   "primary-200": #ABBAED,
   "primary-300": #7990E2,
   "primary-400": #4F6DD8,
   "primary-500": #2A4CC1,
   "primary-600": #213C97,
   "primary-700": #182C6D,
   "primary-800": #0F1A43,
   "primary-900": #070D21,
   "secondary-100": #EBEBF9,
   "secondary-200": #BFBFE8,
   "secondary-300": #9898D7,
   "secondary-400": #7474C3,
   "secondary-500": #464695,
   "secondary-600": #2F2F74,
   "secondary-700": #222258,
   "secondary-800": #191943,
   "secondary-900": #0B0B1E,
   "error-50": #F8D8D8,
   "error-100": #FBE5E5,
   "error-200": #F5C2C2,
   "error-300": #EC8D8E,
   "error-400": #E45859,
   "error-500": #DB2425,
   "error-600": #C11F20,
   "error-700": #A71B1C,
   "error-800": #8C1718,
   "error-900": #721313,
   "success-100": #E4F7D4,
   "success-200": #C9EEB0,
   "success-300": #9BD26A,
   "success-400": #74AF41,
   "success-500": #408305,
   "success-600": #356D03,
   "success-700": #295502,
   "success-800": #1D3C01,
   "success-900": #112301,
   "warning-100": #FEEFC8,
   "warning-200": #FBE19D,
   "warning-300": #F7D069,
   "warning-400": #F4BD2F,
   "warning-500": #EDAC02,
   "warning-600": #BB8802,
   "warning-700": #886301,
   "warning-800": #604601,
   "warning-900": #382801,
   "accent-1": #9B5983,
   "accent-2": #2B3A4D,
   "blueGrey-50": #F8FAFC,
   "blueGrey-100": #EFF2F7,
   "blueGrey-200": #BECBDA,
   "blueGrey-300": #91A6C0,
   "blueGrey-400": #647FA0,
   "blueGrey-500": #3E5878,
   "blueGrey-600": #324862,
   "blueGrey-700": #263950,
   "blueGrey-750": #1F2F42,
   "blueGrey-800": #0D213A,
   "blueGrey-850": #0B1D32,
   "blueGrey-900": #09182A,
);

@function get-color($color) {
  @return map-get($palette, $color)
}

$icon-color: rgba(0, 0, 0, 0.54);
$border-color: #e0e0e0;

$font-size-body1: 16px;
$font-size-body2: 14px;
$font-size-body3: 12px;

@import "common";

$drawer-width: 240px;
$product-thumbnail-size: 72px;
$product-variant-thumbnail-size: 56px;
$order-item-thumbnail-size: 60px;
$checkbox-col-width: $spacing-md + 42px - 12px;
$quantity-col-width: 9%;
$price-col-width: 18%;
$switch-col-width: 46px + 2 * $spacing-md;
