@import "scss/variables";

.StoreIntegrationCheckWarning {

}

.Actions {
  margin: $spacing-lg auto;
  > * {
    margin: $spacing-xs;
  }
}
