@import "scss/variables";

.QuantityChanger {

}

.Quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  background: get-color("white");
  border: 1px solid get-color("grey-200");
  border-radius: $border-radius-default;
  font-size: 15px;
}
