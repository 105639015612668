@import "scss/variablesV3";

.ConnectStoreModal {
  :global(.MuiDialogContent-root) {
    min-height: 180px;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
}

.StoreNameField {
  :global(.MuiInputBase-root) {
    padding: 0;
  }
  :global(.MuiInputAdornment-root) {
    background-color: get-color("grey5");
    align-self: stretch;
    max-height: unset;
    height: unset;
  }
  :global(.MuiInputAdornment-positionStart) {
    padding-left: 14px;
    padding-right: $spacing-sm;
    margin-right: $spacing-sm;
  }
  :global(.MuiInputAdornment-positionEnd) {
    padding-right: 14px;
    padding-left: $spacing-sm;
    margin-left: $spacing-sm;
  }
}

.RadioTileGroup {
  gap: $spacing-md;
}

.ShopifyInstructionsImage {
  padding-bottom: 31.4457831325301%;
  display: block;
  width: 100%;
  background-size: cover;
  & img {
    display: none;
  }
}
