@import "scss/variablesV3";

.MarketplaceFiltersDrawer {
  :global(.MuiCardContent-root > .MuiCircularProgress-root ) {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }
}

.PricingSection {
  .PricingListItem {
    flex-wrap: wrap;
    gap: $spacing-sm;
    :global(.MuiListItemText-root) {
      flex: 1 0 100%;
    }
    :global(.MuiTextField-root) {
      flex: 1 1 1%;
    }
    :global(.MuiFormHelperText-root) {
      flex-basis: 100%;
    }
  }
}
