@import "scss/variables";

.UserBasicInfoSettings {

}

.Errors {
  margin-bottom: $spacing-lg;
}

.profileImageController {
  justify-content: center;
}
