@import "scss/variablesV2";

$first-col-width: 41.6666666666667%; // 5/12

.Header {
  min-height: 56px;

  :global(.MuiAccordionSummary-content) {
    flex-wrap: wrap;
    align-items: center;
  }
}

.Content {
  padding: 0;
}

.TitleContainer {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 100%;
  align-items: center;
  @include lg {
    flex-basis: calc(#{$first-col-width} + 24px);
    max-width: calc(#{$first-col-width} + 24px);
  }
}

.TitleIcon {
  display: block;
  margin-right: $spacing-sm;
  transform: translateX(-2px);
}

.ProgressIndicatorContainer {
  flex: 1 0 100%;
  padding: $spacing-sm 0 0 0;
  @include lg {
    padding: 0 $spacing-lg 0 0;
    flex: 1 1 auto;
  }
}

.ProgressIndicator {
  @include lg {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .ProgressIndicatorText {
      flex: 0 1 auto;
      margin: 0 2em 0 0;
    }

    .ProgressIndicatorBar {
      flex: 1 1 auto;
    }
  }
}

.ProgressIndicatorText {
  margin: 0 0 0.5em;
  > span {
    font-size: 16px;
    line-height: 1;
    margin-right: 4px;
    @include lg {
      display: inline-block;
      min-width: 40px;
      text-align: center;
    }
  }
}

.StepsListContainer {
  padding-bottom: $spacing-sm;
}

.StepsList {
}

.Step {
  :global(.MuiListItemAvatar-root) {
    min-width: 28px + $spacing-md;
  }
  :global(.MuiAvatar-root) {
    width: 28px;
    height: 28px;
    color: get-color("white");
    background-color: get-color("warning-400");
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.StepCompleted {
  :global(.MuiAvatar-root) {
    background-color: get-color("primary-600");
  }
}

.StepActionIcon {
  color: rgba(black, $color-alpha-medium);
}

.StepText {
  > :first-child {
    font-weight: 700;
  }
}

.ActionIconContainer {
  flex: 0 1 auto;
  @include lg {
    order: 3;
  }
}

.ActionIcon {
  padding: $spacing-md ($spacing-md + $spacing-xs) $spacing-md 0;
  color: rgba(black, $color-alpha-medium);
  svg {
    transition: transform .2s ease-out;
  }
}

.ActionIconExpanded svg {
  transform: rotate(-180deg);
}
