@import "scss/variablesV3";

.MarketplaceToolbar {
  border-bottom: 1px solid get-color("grey10");
  padding-left: 12px;
  &.isDesktop {
    border-bottom: unset;
    background-color: get-color("white");
    border-radius: $border-radius-default;
    padding: 0 $spacing-md;
    height: 56px;
  }
}

.Count {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FiltersButton {
  margin: 0 $spacing-xs;
  line-height: 24px;
  &:global(.MuiButton-textSizeLarge) {
    font-size: 1rem;
    font-weight: 400;
  }
}

.OrderingSelect {
  width: 190px;
  :global(.MuiInputBase-input) {
    line-height: 24px;
    border-radius: $border-radius-default;
    padding: $spacing-sm;
    padding-right: $spacing-paper;
  }
  &.labelHidden {
    width: auto;
    :global(.MuiSelect-icon) {
      display: none;
    }
    :global(.MuiSelect-root) {
      padding: 6px $spacing-sm;
      min-width: unset;
    }
  }
  :global(.MuiSvgIcon-root) {
    display: block;
    color: get-color("primary50");
  }
}
