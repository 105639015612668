@import "scss/variables";

.Header {
  margin-bottom: $spacing-lg;
}

.List {
  margin-top: $spacing-lg;
}

.ListSkeleton {
  margin: $spacing-lg 0;
}
