@import "scss/variables";

.Button {
  border: 0;
  box-shadow: none;
  outline: none;
  padding: $spacing-sm $spacing-md;
  border-radius: $border-radius-default;
  background: get-color("grey-100");
  color: get-color("grey-700");
  font-size: 15px;

  &:hover {
    cursor: pointer;
    background: get-color("grey-300");
    color: get-color("grey-800");
  }

  &:focus {
    background: get-color("grey-50");
    color: get-color("grey-900");
  }

  &.primary {
    background: get-color("primary-500");
    color: get-color("grey-100");

    &:hover {
      background: get-color("primary-700");
    }

    &:focus {
      background: get-color("blue-grey-5");
    }

    &[disabled] {
      background: get-color("grey-200") !important;
      color: get-color("grey-500") !important;
    }
  }

  &.secondary {
    background: none;
    border: 1px solid get-color("primary-500");
    color: get-color("primary-500");

    &:hover {
      border-color: get-color("primary-700");
      color: get-color("primary-700");
    }

    &:focus {
      border-color: get-color("primary-400");
      color: get-color("primary-400");
    }

    &[disabled] {
      border-color: get-color("grey-300");
      color: get-color("grey-300");
    }
  }
}
