@import "scss/variablesV3";

.Button {
    color: get-color("primary40");
    flex: 1 1 50%;
    align-self: stretch;
    padding: ($spacing-paper / 2);
    @include sm { padding: ($spacing-paper / 2) $spacing-md; }
    @include desktop { padding: ($spacing-paper / 2) $spacing-paper; }

    :global(.MuiTypography-root) {
        flex-grow: 1;
        padding: 0 $spacing-sm;
        white-space: nowrap;
    }
}

.Divider {
    &:global(.MuiDivider-vertical) {
        height: 32px;
        align-self: center;
    }
}
