@import "scss/variablesV3";

.DestructiveActionConfirmationModal {

}

.Cancel {
  color: get-color("error5");
  border-color: get-color("error5");

  &:hover {
    background-color: get-color("error1");
  }
}

.Confirm {
  background-color: get-color("error5");
  color: get-color("white");

  &:hover {
    background-color: get-color("error6");
  }
}
