@import "scss/variables";

.UserSettingsPage {
  :global(.MuiCardHeader-root),
  :global(.MuiCardContent-root),
  :global(.MuiCardActions-root) {
    padding: 24px;
  }
  :global(.MuiCardHeader-title) {
    font-weight: 700;
  }
}

.PageHeader {
  margin-bottom: $spacing-lg;
}

.Card:not(:last-child) {
  margin-bottom: $spacing-lg;
}
