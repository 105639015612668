@import "scss/variables";

.RetailerOrder {
  background: get-color("white") !important;
  padding: $spacing-md;
  @include md {
    padding: $spacing-paper;
  }
}

// Order information area

.BasicInformation {
  white-space: nowrap;
  > * {
    display: inline-block;
    vertical-align: middle;
  }
}

.OrderNumber {
  display: inline-block;
  font-weight: 700 !important;
  margin: 0 .5em;
}

.Statuses {
  margin-bottom: - $spacing-sm;
  > * {
    margin-bottom: $spacing-sm;
    margin-right: $spacing-sm;
  }
}

.DatesAndNumbers {
  margin: $spacing-sm 0 $spacing-md;
  color: get-color("grey-600");
}

.OrderInformation {
  flex-flow: column nowrap;
   @include xl {
    flex-flow: row wrap;
    align-items: center;
    .BasicInformation {
      margin-right: 1em;
    }
    .DatesAndNumbers {
      flex-basis: 100%;
      margin: $spacing-sm 0 0;
      order: 3;
    }
  }
}

// Address area

.Address {
  margin-top: $spacing-md;
}


// Header area (Order Information + Address)

.Header {
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  @include md {
    flex-flow: row nowrap;
    > * {
      flex-basis: 49%;
    }
  }
  @include xl {
    .OrderInformation {
      flex-basis: 66%;
      min-width: 600px;
    }
    .Address {
      flex-basis: 33%;
      max-width: 450px;
      margin: 0;
    }
  }
}

// Alerts area

.Alerts {
  margin: $spacing-paper 0 0 0;
  .Link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
  > * {
    margin-top: $spacing-md;
  }
}

// DPOs list area

.OrdersList {
  > * {
    margin-top: $spacing-paper;
  }
}

// Footer area

.TotalCosts {
  flex: 1 0 100%;
  margin-bottom: .5em;
}

.PaidByCustomer {
  margin-top: $spacing-sm;
  span {
    font-weight: 700;
  }
}

.Footer {
  color: get-color("grey-600");
  flex-direction: column;
  margin: $spacing-md 0 0;
  padding: 0 $spacing-md 0 0;
  text-align: right;
  @include md {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .PaidByCustomer {
      margin-top: 0;
    }
    .Profit {
      order: 3;
      margin-left: .5em;
    }
  }
}
