@import "scss/variablesV3";
@import "scss/mixins";

$tile-size: 92px;
$gap: 12px;

.ImageGallery {
  @include clearfix();
  margin-right: -$gap;
}

.Tile {
  position: relative;
  float: left;
  width: $tile-size;
  height: $tile-size;
  margin-right: $gap;
  margin-bottom: $gap;
  border-radius: $border-radius-default;

  &.featured {
    width: $tile-size * 2 + $gap;
    height: $tile-size * 2 + $gap;
  }

  &.selected {
    box-shadow: 0 0 0 1px get-color("primary50");
  }

  .Overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparentize(get-color("grey40"), 0.4);
    border-radius: $border-radius-default;
    z-index: 1;
    opacity: 0;
    transition: opacity .2s;

    &.keepOpen {
      opacity: 1;
    }
  }

  &:hover {
    .Overlay {
      opacity: 1;
    }
  }
}

.Image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: $border-radius-default;
}

.Checkbox {
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 2;
}

.CheckboxBackground {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 16px;
  height: 16px;
  background: get-color("white");
  z-index: 1;
  border-radius: $border-radius-default;
}

.MoreButton {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 4px;
  right: 4px;
  transform: rotate(-90deg);
  z-index: 2;

  * {
    font-size: 16px;
  }
}

.EnlargeButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2px;
  z-index: 2;
  background: get-color("secondary5");

  &:hover {
    background: get-color("secondary10");
  }

  svg {
    width: 32px;
    height: 32px;
  }
}

.featured .EnlargeButton {
  padding: 4px;

  svg {
    width: 36px;
    height: 36px;
  }
}
