@import "scss/variablesV3";

.SyncedProductsList {
  border-top: 1px solid get-color("primary10");
}

.Table {
  border-bottom-left-radius: $border-radius-default;
  border-bottom-right-radius: $border-radius-default;
}

.ProductRow:last-child {
  :global(.MuiTableCell-root) {
    border-bottom: none !important;
  }
}

.Pagination {
  margin: $spacing-lg 0 0 0;
}

.ListItemSkeleton {
  margin-bottom: $spacing-xs;
  height: 90px;
}

.ProductCol {
  @include xl {
    min-width: 350px;
    max-width: 350px;
  }
}

.InventoryCol {
  min-width: 150px;
  max-width: 150px;
}

.PricingCol {
  min-width: 300px;
}

.ActionsCol {
  min-width: 200px;
  max-width: 200px;
}

.Center {
  display: flex;
  align-items: center;
}

.Actions {
  justify-content: flex-end;
}

.TableHeader {
  background: get-color("secondary5");

  :global(.MuiTableCell-head) {
    font-weight: 700;
    color: get-color("grey80");
  }
}

.Helper {
  margin-left: $spacing-xs;
  color: get-color("primary50");
}

.Alert {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
