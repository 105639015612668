@import "scss/mixins";

.UserProfileButton {
  border-radius: 24px;

  :global(.MuiAvatar-root) {
    width: 36px;
    height: 36px;
  }

  :global(.MuiButton-startIcon) {
    margin-right: 0;
  }

  :global(.MuiButton-endIcon) {
    margin-left: 0;
  }
}

.LabelText {
  max-width: 200px;
  @include text-overflow-ellipsis;
}
