@import "scss/variablesV3";

.SkeletonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: $spacing-lg 0;

  > * {
    width: 100%;
  }
}

.ListSkeletons {
  margin: $spacing-lg 0;

  > *:not(:first-child) {
    margin-top: $spacing-sm;
  }
}

.PaginationSkeleton {
  max-width: 200px;
  height: 50px;
}

.FiltersBar {
  margin-top: $spacing-lg;
}

.Pagination {
  margin-top: $spacing-lg;
}

.SelectAllButton {
  margin-left: -6px;
}

.Alert {
  margin-top: $spacing-lg;
}

.RemoveAlert {
  margin-bottom: $spacing-md;
}
