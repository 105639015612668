@import "scss/variablesV3";

.CursorPagination {
  display: flex;
  justify-content: center;
  align-items: center;

  :global(.Mui-disabled) {
    opacity: 0.5;
  }
}

.Page {
  min-width: 50px;
  margin: 0 $spacing-md;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.Button {
  display: flex;
  width: 42px;
  height: 42px;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-default;
  color: get-color("grey-70");
  transition: background .2s;

  &:hover {
    background: get-color("grey-20");
  }
}
