@import "scss/variablesV3";

.TopBanner {
  color: get-color("secondary100");
  padding: 9px;
  min-height: 38px;
  background-color: get-color("background");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.Content {
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  align-items: center;
  letter-spacing: 0.02em;
}

.CloseButton {
  position: absolute;
  right: 10px;
  font-size: 10px;
}
