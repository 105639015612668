@import "scss/variablesV3";

.ImportListBatchActionModal {

}

.DestructiveButton {
  &:global(.MuiButton-outlined) {
    color: get-color("error5") !important;
    border-color: get-color("error5") !important;
  }

  &:global(.MuiButton-contained) {
    color: get-color("white") !important;
    background-color: get-color("error5") !important;

    &:hover {
      background-color: get-color("error6") !important;
    }
  }
}
