@import "scss/variables";

.ProgressBar {

}

.Bar {
  height: 12px;
  border-radius: $border-radius-default;
}

.Percentage {
  min-width: 50px;
}
