@import "scss/variables";

.CardForm {

  &.danger {
    border: 2px solid get-color('error-500');
  }

  &:not(:last-child) {
    margin-bottom: $spacing-lg;
  }

  .CardHeader {
  }

  :global(.MuiCardHeader-subheader) {
    margin-top: $spacing-sm;
  }

  .Content {
  }

  .Actions {
  }
}

.DangerButton {
  background: get-color('error-500');

  &:hover {
    background: get-color('error-600');
  }
}

.ProgressIcon {
  margin-right: $spacing-xs;
}
