@import "scss/variablesV3";

$toolbar-height: 52px;

.Wrapper {
  height: 400px;
}

.ImportListProductPricingTab {
  height: 400px - $toolbar-height;
}


.Loader {
  height: 400px;
  padding: 24px;
}

.Toolbar {
  height: $toolbar-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacing-md;
  border-bottom: 1px solid mix(get-color("grey5"), get-color("grey10"));

  :global(.MuiGrid-container) {
    margin-top: 0;
    margin-bottom: 0;
  }

  :global(.MuiGrid-item) {
    height: 52px;
  }
}

.Actions {
  > *:not(:last-child) {
    margin-right: $spacing-xs;
  }
}

.AutoSwitcher {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  :global(.MuiFormControlLabel-root) {
    margin: 0;
  }
}

.ImageCell {
  width: 48px;
}

.Image {
  width: 48px !important;
  height: 48px !important;
}

.Input {
  width: 100px;
}

.EditableCell {
  display: inline-block;
  padding-left: 14px;
}

.Variant {
  transition: opacity .2s;

  &.inactive {
    opacity: 0.3;
  }
}

.SetAllPricesBox {
  width: 250px;
  padding: 24px 16px 12px 16px;
}

.SetAllPricesActions {
  margin-top: $spacing-lg;
  text-align: right;

  > *:not(:first-child) {
    margin-left: $spacing-xs;
  }
}

.Alert {
  border-radius: 0;
}

.NegativeProfitHelper {
  display: inline-block;
  margin-top: -2px;
  margin-left: $spacing-xs / 2;
  font-size: 1.25rem;
}

.negative {
  color: mix(get-color("warning5"), get-color("error5"), 75%);
}
