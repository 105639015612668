@import "scss/variables";

.MarkFulfilledModal {
}

.MarkFulfilledForm {
  padding: 12px 0
}

.NonFieldError {
}

.SubmitInput {
  display: none;
}
