@import "scss/variables";

.UniversalNotification {
  align-items: center;
}

.Buttons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  > * {
    margin-right: $spacing-sm;
  }

  > *:last-child {
    margin-right: 0;
  }
}

.ActionButton {
  font-weight: 700;
  background: rgba(0, 0, 0, 0.2);
}
