@import "scss/variablesV3";

.SyncListCsvExportModal {

}

.Description {
  margin-top: $spacing-md;
}

.Selector {
  margin-top: $spacing-md;
}

.Group {
  margin: $spacing-md -8px 0 -8px;

  > * {
    flex-basis: calc(100% - 8px * 2);
    margin: 8px;

    @include sm {
      flex-basis: calc(100% / 2 - 8px * 2);
    }

    @include md {
      flex-basis: calc(100% / 3 - 8px * 2);
    }
  }
}

.Alert {
  margin: $spacing-md 0;
}
