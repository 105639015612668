@import "scss/variables";

.MarketplaceBanner {
  display: block;
  cursor: pointer;
}

.Card {
  background: transparent;
}

.Media {
  width: 100%;
  height: 0;
  padding-bottom: 62%;
}
