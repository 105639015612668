@import "scss/variables";

.UserDeleteAccountForm {

}

.Bold {
  font-weight: 700;
}

.Error {
  margin-bottom: $spacing-lg;
}
