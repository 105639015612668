@import "scss/variables";

.Root {

}

.Header {
  :global(.MuiAccordionSummary-content) {
    flex-wrap: nowrap;
  }
}

.AvatarContainer {
  flex: 0 0 56px;
  @include lg {
    flex-basis: 72px;
  }
}

.HeaderContent {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  @include lg {
    flex-wrap: nowrap;
  }
}

.LogoContainer {
  flex: 0 0 170px;
  display: inline-flex;
  align-self: center;
  height: 32px;
}

.InfoContainer {
  flex-basis: 100%;
  @include lg {
    flex-basis: auto;
    align-self: center;
    padding-left: $spacing-lg;
  }
}

.BetaBadge {
  margin-left: $spacing-sm;
}

.Intro {
}

.Outro {
  margin-top: $spacing-md;
}

.Content {
  flex-flow: column;
}

.StepList {
  margin: 0 (-$spacing-md);
}

.Root-connected {
  .Avatar {
    background-color: get-color("success-600");
  }
}

.Root-expired {
  .Avatar {
    background-color: get-color("error-600");
  }
  .InfoContainer {
    font-weight: bold;
    color: get-color("error-500");
  }
}
