@import "scss/variablesV3";

.ImageButton {
  position: relative;
  border-radius: $border-radius-default;
  transition: box-shadow .2s;

  &:hover {
    box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.1);
  }
}

.ImageInProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
}

.Image {
  flex-shrink: 0;
  display: block;
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: $border-radius-default;
}
