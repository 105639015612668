@import "scss/variables";
@import "scss/mixins";

.HorizontalNestedList {
  position: relative;
  overflow: hidden;
  height: 100%; // the component should adapt to the container at all times
  display: flex;
  flex-flow: column nowrap;
}

.Columns {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  transition: left .2s ease-out;
  height: calc(100% - 46px);
  background-color: inherit;
}

.Column {
  flex: 0 0 100%;
  height: 100%;
  overflow: auto;
  background-color: inherit;
  @include styled-scrollbar(#B7BCE3, #FAFAFA);

  &:first-child .SelectParent {
    display: none;
  }

  :global(.MuiList-root) {
    background-color: inherit;
  }

  :global(.MuiListItem-gutters) {
    padding-right: $spacing-sm;
  }
}

.ColumnHeader {
  :global(.MuiListItem-dense) {
    min-height: 48px;
  }

  :global(.MuiListItemText-primary) {
    font-weight: bold;
    color: black;
  }

  > span {
    display: block;
    padding: 0 $spacing-md;
  }
}

.Item {
  transition: background-color 0.2s;

  &.ItemSelected {
    background-color: get-color("grey-100");
  }
}

.Controls {
  padding: 0;

  .Buttons {
    display: flex;
    width: 100%;
  }

  .Button {
    border: none;
    border-radius: 0;
    flex-grow: 1;

    &:not(:last-child) {
      border-right: 1px solid get-color('grey-100');
    }
  }
}

.HorizontalNestedList.isMulticolumn {
  .Columns {
    left: auto !important;
  }
  .Column {
    flex: 1 1 1%;
    &:not(:last-child) {
      border-right: 1px solid get-color("grey-100");
    }
  }
  .Controls {
    display: none;
  }
}
