@import "scss/variablesV3";

.ImageZoom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: $spacing-md;
  outline: 0;
  cursor: zoom-out;

  @include lg {
    padding: $spacing-lg;
  }
}

.Image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  border-radius: $border-radius-large;
  background: get-color("white");
}
