@import "scss/variables";

.Pagination {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-lg;
  align-items: flex-end;

  @include lg {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .Counter {
    margin-bottom: $spacing-md;

    @include lg {
      margin-bottom: 0;
    }
  }
}
