@import "scss/variables";

.BetaBadge {
    height: 20px;
    background-color: get-color("secondary-400");

    &.Small {
        height: 12px;
        line-height: 12px;
        font-size: 8px;
        :global(.MuiChip-label) {
            padding-left: 4px;
            padding-right: 4px;
        }
    }
}
