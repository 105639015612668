@import "scss/variablesV3";

.ImportListProductTable {
  max-height: 500px;
  overflow-y: auto;

  :global(.MuiTableCell-root) {
    background: get-color("white");
    transition: background .2s;
  }

  :global(.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline) {
    transition: border .2s;
  }

  :global(.MuiTableCell-head) {
    height: 42px;
    line-height: 1;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 12px;
    font-weight: 700;
    color: get-color("grey70");
  }

  :global(.MuiTableCell-body) {
    padding: 12px 16px;
    line-height: 1;
    color: get-color("grey40")
  }

  :global(.MuiTableHead-root:hover .MuiTableCell-head) {
    background: mix(get-color("grey0"), get-color("grey5"));
  }

  // For any input which parent row is not hovered, AND the input is NOT focused, change the border to transparent
  :global(.MuiTableRow-root:not(:hover) .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline) {
    border-color: transparent;
  }

  :global(.MuiTableBody-root .MuiTableRow-root:hover .MuiTableCell-body) {
    background: mix(get-color("grey0"), get-color("grey5"));
  }
}
