@import "scss/variablesV3";

.ActiveFilters {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  padding: 14px 28px;
  border-top: 1px solid get-color("grey10");

  > *:not(:last-child) {
    margin-right: 8px;
  }
}

.ChipList {
  flex-grow: 1;
}
