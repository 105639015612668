@import "scss/variables";

.ModalystOrderPaymentCompleteModal {

}

.ModalBodyContent {
    padding: 24px;
    @include lg {
      min-width: 600px;
    }
}
