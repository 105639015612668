@import "scss/variablesV3";

.ProductSlateActionsContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-color: rgba(get-color("white"), .45);
}

.MainActions, .MoreActions {
  pointer-events: auto;
  position: absolute;

  :global(.MuiButton-root:not(.MuiButton-containedPrimary):not(.Mui-disabled)), :global(.MuiIconButton-colorSecondary) {
    color: get-color("primary40");
    background-color: get-color("white");
    &:hover {
      background-color: get-color("primary5");
    }
  }

  :global(.MuiButton-contained:not(.MuiButton-disableElevation):not(.MuiButton-disabled):not(:hover)) {
    box-shadow: 0px 1px 18px 0px rgba(get-color("black"), .12),
                0px 6px 10px 0px rgba(get-color("black"), .14),
                0px 3px 5px -1px rgba(get-color("black"), .20);
  }

  :global(.MuiButton-contained.Mui-disabled) {
    color: rgba(get-color("secondary100"), .7);
    background-color: rgba(get-color("grey70"), $color-alpha-low);
    box-shadow: none !important;
  }
}

.MainActions {
  display: inline-flex;
  flex-flow: column;
  align-items: stretch;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  :global(.MuiButton-label) {
    white-space: nowrap;
  }

  :global(.MuiButton-root) {
    margin: $spacing-xs 0;
    border-radius: 18px;
  }
}

.MoreActions {
  top: $spacing-md;
  right: $spacing-md;
}
