@import "scss/variablesV3";

.ProductDraftSlate {
  position: relative;
}

.CardMedia {
  background-color: get-color("skeleton");
  position: relative;
  height: 0;
  padding-top: 110%;
  background-position: center;
}

.Actions {
  background-color: rgba(0, 0, 0, $color-alpha-medium);
}

.DeleteMenuItem {
  color: get-color("error6")
}
