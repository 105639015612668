@import "scss/variables";

.MarketplaceItemVariantsTable {

}

.Head {
  font-size: 14px;
  font-weight: 700;
}

.Cell {
  font-size: 14px;
}
