@import "scss/variablesV3";

.ItemCustomizerModal {
  margin: $spacing-lg;
  border-radius: $border-radius-default;
  overflow: hidden;
}

.Editor {
  width: 100%;
  height: 100%;
  border: none;
}

.EditorIFrame {
  width: 100%;
  height: 100%;
  border: none;
}

.Wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: transparentize(get-color("grey10"), 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Loader {
  width: 100%;
  height: 8px;
  border-radius: $border-radius-default;
  max-width: 400px;
}

.Alert {
  max-width: $screen-sm;
  margin: 0 auto;
}
