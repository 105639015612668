@mixin styled-scrollbar($thumb-color, $track-color) {
  scrollbar-width: thin;
  scrollbar-color: $thumb-color $track-color;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
  }

  &::-webkit-scrollbar-track {
    background-color: $track-color;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin fill-parent() {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin top-arrow() {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 50%;
  width: 10px;
  height: 10px;
  background-color: inherit;
  transform: translateY(-50%) translateX(50%) rotate(45deg);
  z-index: 0;
}

@mixin text-overflow-ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
