@import "scss/variablesV3";

.MarketplaceItemActions {
  display: flex;
  align-items: center;
  gap: $spacing-md;
  & > * { flex: 0 0 auto; }

  :global(.MuiButton-outlinedPrimary), :global(.MuiIconButton-colorPrimary) {
    border: none;
    background-color: get-color("white");
    &:hover {
      background-color: get-color("primary0");
    }
  }
    :global(.MuiIconButton-colorPrimary) {
      padding: 8px;
    }
}

.SupplierProductAdminMenu {
  margin-left: auto;
}
