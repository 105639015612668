@import "scss/variables";

.SupplierInfoBox {
  :global(.MuiCardHeader-action) {
    margin-left: $spacing-md;
    align-self: center;
  }
}

.Avatar {
  background: transparent;
}

.TimeDisplay {
  white-space: nowrap;
}

.Rating {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px;
}

.RatingStar {
  margin-right: $spacing-xs;
  color: get-color("warning-500");
}

.RatingValue {
  color: get-color("grey-700");
}

.StatsContainer {
  margin-bottom: $spacing-md;
  justify-content: space-between;
}

.StatValue {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.ReadMoreButton {
  &:hover {
    cursor: pointer;
  }
}

.ExternalLink {
  margin-left: $spacing-sm;
}

.SupplierName {
  font-weight: 700;
}
