@import "scss/variablesV3";

$line-height: 20px;

.ProductSlate {
  position: relative;
  overflow: visible;
  font-size: $font-size-body2;
  line-height: $line-height;
  outline-color: transparent;
  transition: outline-color .2s ease-out;

  :global(.MuiCardHeader-root), :global(.MuiCardContent-root) {
    padding: $spacing-sm ($spacing-paper / 2);
  }

  :global(.MuiCardHeader-root) {
    display: block;
  }

  :global(.MuiCardHeader-title) {
    font-size: inherit;
  }

  :global(.MuiCardMedia-root) {
    font-size: inherit;
    background-color: get-color("skeleton");
  }

  > a {
    color: inherit;
    text-decoration: unset;
  }

  .Actions {
    top: 56px;
    bottom: 168px;
  }
}

.SourceInfo {
  font-size: $font-size-body3;
  span { display: block; }

  > * {
    flex: 1 1 auto;
  }

  .InventoryType {
    color: get-color("error7");
    width: 100%;
  }

  .SupplierName {
    flex-basis: 1%;
    margin-right: $spacing-sm;
  }

  .SupplierRating {
    flex: 0 1 auto;
    display: inline-flex;
    align-items: center;

    .SupplierRatingStar {
      position: relative;
      width: 18px;
      height: 18px;
      margin-right: $spacing-xs;
      transform: translateY(-1px);
    }
  }
}

.CardMedia {
  height: 0;
  padding-top: 115%;
  background-position: center;
  // Style Commerce crops their images weirdly, so the focus point
  // is in the bottom of the image.
  &.nameBrands {
    background-position: bottom;
  }
}

.ProductName {
  font-weight: 700;
}

.Quantity, .RetailPrice {
  font-size: $font-size-body3;
}

.InventoryType {
  &:empty:after {
    content: "\00a0";
  }
}
