@import "scss/variables";

.UserRequestDataForm {

}

.Errors {
  margin-bottom: $spacing-lg;
}

.Bold {
  font-weight: 700;
}
