@import "scss/variablesV3";

@mixin bar-padding {
  padding: 6.75px 24px;
}

.ListFiltersBar {
  background: get-color("white");
  border-radius: $border-radius-default;
  overflow: hidden;
}

.Bar {
  position: relative;
  @include bar-padding;
}

.Grid {
  align-items: center;
}

.SelectAllButtonWithLabel {
  display: flex;
  align-items: center;
}

.Label {
  margin-left: 2px;
  font-size: 14px;

  @include lg {
    font-size: 16px;
  }
}

.Overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: get-color("white");
  @include bar-padding;
  display: flex;
  align-items: center;
  z-index: -1;

  &.OverlayOpen {
    z-index: 1;
  }
}

.Button {
  color: get-color("grey40");

  .Icon {
    color: get-color("primary40");
    width: 18px;
    height: 18px;
  }
}
