@import "scss/variables";

.SingleSelectButton {
  min-width: 0;
  height: 28px;
  padding: 2px 4px;

  :global(.MuiButton-endIcon) {
    margin: 0 0 0 4px;
  }
}

.CompositeButtonElement {
  min-width: 0;
  height: 28px;
  padding: 2px 4px;

  + .CompositeButtonElement {
    margin-left: -4px;
  }
}
