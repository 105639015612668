@import "scss/variablesV3";

.ToggleButtonGroup {
  display: flex;

  > :global(.MuiToggleButton-root) {
    flex: 1 1 auto;
    line-height: 2;
    padding: 6px 0;
  }

  > :global(.MuiToggleButton-root.Mui-selected) {
    background-color: get-color('primary50');
    color: get-color('white');
  }
}
