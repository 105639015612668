@import "scss/mixins";

.Container {
  @include fill-parent;
  border-radius: inherit;
  pointer-events: none;
}

.ContainerIn {
  outline-width: 2px;
  outline-style: solid;
  outline-offset: -2px;
}
