@import "scss/variablesV3";

.CategoriesPromoGrid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: auto;
  gap: 8px;
}

.womensFashion { grid-column: 1 / 13; grid-row: 1; min-height: 192px; }
.kidsBabies { grid-column: 1 / 7; grid-row: 2; min-height: 192px; }
.newSuppliers { grid-column: 7 / 13; grid-row: 2; }
.mensFashion { grid-column: 1 / 13; grid-row: 3; min-height: 226px; }
.jewelryAccessories { grid-column: 1 / 13; grid-row: 4; min-height: 202px; }
.beautyHealth { grid-column: 1 / 13; grid-row: 5; }
.homeGarden { grid-column: 1 / 13; grid-row: 6; }

@include md {
  .womensFashion { grid-column: 1 / span 8; grid-row: 1; min-height: 230px;}
  .newSuppliers { grid-column: 9 / 13; grid-row: 1; }
  .kidsBabies { grid-column: 1 / span 6; grid-row: 2; min-height: 230px;}
  .mensFashion { grid-column: 7 / 13; grid-row: 2; }
  .jewelryAccessories { grid-column: 1 / span 5; grid-row: 3 / span 2; }
  .beautyHealth { grid-column: 6 / 13; grid-row: 3; }
  .homeGarden { grid-column: 6 / 13; grid-row: 4; }
}

@include xl {
  .womensFashion { min-height: 280px;}
  .kidsBabies { min-height: 260px; }
  .jewelryAccessories { min-height: 320px; }
}
