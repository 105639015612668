@import "scss/variables";

.InputWrapper {
  min-height: 40px;

  .Input {
    display: block;
    width: 100%;
    min-height: 40px;
    height: 100%;
    outline: none;
    padding: $spacing-sm $spacing-md;
    border: 1px solid get-color('grey-400');
    border-radius: $border-radius-default;
    font-size: 14px;
    transition: border $transition-default, color $transition-default, box-shadow $transition-default;
    color: get-color('grey-700');

    &.borderRadiusFirst {
      border-radius: $border-radius-default $border-radius-default 0 0;
      @include md {
        border-radius: $border-radius-default 0 0 $border-radius-default;
      }
    }

    &.borderRadiusLast {
      border-radius: 0 0 $border-radius-default $border-radius-default;
      @include md {
        border-radius: 0 $border-radius-default $border-radius-default 0;
      }
    }

    &.borderRadiusAll {
      border-radius: $border-radius-default;
    }

    &.borderRadiusNone {
      border-radius: 0;
    }

    &::placeholder {
      color: get-color('grey-500');
    }

    &:hover {
      border-color: get-color('grey-700');
    }

    &:focus {
      border-color: get-color('grey-700');
      box-shadow: 0 0 0 1px get-color('grey-700');
    }

    &[disabled] {
      color: get-color('grey-300');
      border-color: get-color('grey-300');
      background: transparent;

      &::placeholder {
        color: get-color('grey-300');
      }
    }

    &.error {
      border-color: get-color('error-500');
      color: get-color('error-500');

      &:hover {
        border-color: get-color('error-600');
      }

      &:focus {
        border-color: get-color('error-600');
        box-shadow: 0 0 0 1px get-color('error-600');
      }
    }
  }
}
