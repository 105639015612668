@import "scss/variables";

.BatchActionsBar {
  align-items: center;
}

.Label {
  font-size: 14px;

  @include lg {
    font-size: 16px;
  }
}
