@import "scss/variablesV3";

.Title {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
}

.Helper {
  margin-left: $spacing-md;
}

.Subtitle {
  font-size: 14px;
  line-height: 20px;
}

.Title + .Subtitle {
  margin-top: $spacing-xs;
}
