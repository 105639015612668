@import "scss/variables";

.OrderItemName {
  .Name {
    font-weight: 700 !important;
    color: get-color('grey-700');
  }

  .ExternalButton {
    margin: -3px 0 0 .5em;
  }
}
