@import "scss/variables";

.List {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;

  .Element {
    margin: $spacing-xs 0;

    &.inline {
      display: inline-block;
      margin: $spacing-xs $spacing-sm;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
