@import "scss/variablesV3";

$profile-box-diagonal: 150px;

.ImageUpload {

}


.ProfileImageBox {
  display: block;
  justify-content: center;
  position: relative;
  width: $profile-box-diagonal;
  height: $profile-box-diagonal;
  align-items: center;
  object-fit: contain;
  border-radius: 50%;
  color: get-color('white') !important;

  .AddButton, .ChangeButton {
    position: absolute;
    top: 0;
    left: 0;
    height: $profile-box-diagonal;
    width: $profile-box-diagonal;
    color: white;
    padding: 0;
    border-radius: 50%;

    .PlusIcon {
      display: block;
      width: 100%;
    }

    .ButtonText {
      display: block;
      padding: 0 22px;
      line-height: 1.1rem;
    }

    :global(.MuiButton-label) {
      display: block;
      width: 100%;
      border-radius: 50%;
      padding: 0;
    }

    :global(.MuiTouchRipple-root) {
      border-radius: 50%;
    }
  }

  &.ChangeProfileImage {
    .ChangeButton {
      opacity: 0;
      transition: opacity .3s ease-in-out,
                  background .3s ease-in-out;
      background: rgba(0, 0, 0, 0);
    }

    &:hover .ChangeButton {
      opacity: 1;
      background: rgba(0, 0, 0, 0.3);
    }

    .Image {
      width: $profile-box-diagonal;
      height: $profile-box-diagonal;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &.AddProfileImage {

    .Icon {
      font-size: $profile-box-diagonal;
    }

    .IconOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: $profile-box-diagonal;
      height: $profile-box-diagonal;
      border-radius: 50%;
      background: rgba(5, 10, 46, 0.6);
    }
  }
}

.ProfileImageInput {
  min-height: ($profile-box-diagonal);
}

.DeleteButtonBox {
  width: 100%;
  padding-top: 12px;

  Button {
    width: 100%;
  }
}
