@import "scss/variablesV3";

.MarketplaceCategoryDropdown {
  display: flex;
  align-items: center;
}

.TriggerButton {
  padding-left: 11px;
  padding-right: 12px;
  flex: 1 0 auto;
  :global(.MuiButton-label) {
    justify-content: space-between;
  }
}

.ClearButtonContainer {
  width: 0;
  overflow: hidden;
  transition: width .2s;
  :global(.MuiIconButton-root) { margin: 0 $spacing-xs; }
  &.isEnabled { width: 30px + 2 * $spacing-xs; }
}
