@import "scss/variables";

.ArchivedProductsList {

}

.Pagination {
  margin: $spacing-md 0;
}

.List {

}

.ListItem {
  border-radius: 0;

  &:first-child {
    border-radius: $border-radius-default $border-radius-default 0 0;
  }

  &:last-child {
    border-radius: 0 0 $border-radius-default $border-radius-default;
  }

  &:not(:last-child) {
    border-bottom: 0;
  }
}


.ListItemSkeleton {
  margin-bottom: $spacing-xs;
  height: 325px;

  @include sm {
    height: 170px;
  }

  @include md {
    height: 200px;
  }

  @include lg {
    height: 71px;
  }
}
