@import "scss/variablesV3";

.ListSkeleton {
  > * {
    margin-bottom: 2px;
  }
}

.PaginationSkeleton {
  width: 100%;
  max-width: 200px;
  height: 40px;
  margin: $spacing-lg auto 0 auto;
}

.NotConnectedAlert {
  margin-bottom: $spacing-md;
}

.FiltersBar {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.MainContainer {
  margin-left: -16px;
  margin-right: -16px;

  @include md {
    margin: 0;
  }
}

.RemoveAlert {
  margin-bottom: $spacing-md;
}
