@import "scss/variables";

.MarketplaceItemShippingTab {

}

.AdditionalPrice {
  color: get-color("grey-700");
}

.ShippingDisabled {
  color: get-color("grey-700");
}

.DomesticIndicator {
  display: inline-flex;
  width: 20px;
  height: 20px;
  margin-right: $spacing-sm;
  font-size: 14px;
  background: get-color("primary-500");
  color: get-color("grey-100");
}

.FreeShipping {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  color: get-color("success-600");
}

.FreeShippingIcon {
  margin-right: $spacing-xs;
  font-size: 20px;
}

.DisabledShipping {
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  color: get-color("grey-500");
}

.DisabledShippingIcon {
  margin-right: $spacing-xs;
  font-size: 18px;
}
