@import "scss/variables";

.MarketplaceItemProcessingTimeData {

}

.Country {
  margin-bottom: $spacing-sm;
}

.Flag {
  height: 14px;
}

.AdditionalShippingInfo {
  max-width: $screen-sm;
  margin-top: $spacing-sm;
  color: get-color("grey-700");
}
