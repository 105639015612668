@import "scss/variablesV3";

.ProductSearchForm {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.SearchInput {
  flex: 1 1 auto;
  border-radius: $border-radius-default;
  background-color: white;
  padding-right: $spacing-sm;

  input {
    padding: 8px 12px;
    height: 20px;
    line-height: 20px;
  }

  hr {
    margin: 0 $spacing-xs;
  }
}
