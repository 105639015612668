@import "scss/variables";

.SidebarLeftTemplate {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include md {
    min-height: 100vh;
    flex-direction: row;
    align-items: stretch;
  }
}

.MainContainer {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  @include desktop {
    margin-top: 0;
    padding-left: 240px;
  }
}

.Main {
  flex-grow: 1;
}

.Main:not(.disableGutters) {
  margin-bottom: 85px; // Leave some space for the Intercom floating button...
  padding: 16px;

  @include md {
    padding: $spacing-paper $spacing-lg;
  }

  @include lg {
    padding: $spacing-paper 40px;
  }
}
