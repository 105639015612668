@import "scss/variablesV3";

.Alert {
  padding: $spacing-sm $spacing-md;

  &:global(.MuiAlert-root) {
    flex-direction: column;

    @include md {
      flex-direction: row;
    }
  }
}
