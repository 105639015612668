@import "scss/variables";

.EmptyListSplash {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $spacing-lg * 3;
}

.Header {
  font-weight: 700;
  margin-bottom: $spacing-md;
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $spacing-lg * 2;
  margin-left: -4px;
  margin-right: -4px;

  > * {
    margin: 4px;
  }
}
