@import "scss/variables";

.StoreLocationForm {

}

.Flag {
  display: inline-block;
  margin-right: $spacing-sm;
}

.Errors {
  margin-bottom: $spacing-lg;
}
