@import "scss/variablesV3";

.FiltersPanel {

}

.Paper {
  width: 400px;
  max-width: 100vw;
  overflow-x: hidden;
}

.FiltersPanelHeader {
  padding-top: $spacing-sm;
  padding-bottom: $spacing-sm;
  border-bottom: $border-divider;
  :global(.MuiCardHeader-action) {
    margin-top: 0;
  }
}

.FiltersPanelContent {
  padding: 0;
  flex-grow: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.FiltersPanelActions {
  box-sizing: content-box;
  height: 48px;
  border-top: $border-divider;
  padding: $spacing-sm $spacing-md;
  align-items: center;
  justify-content: space-between;

  :global(.MuiTypography-root) {
    display: inline-flex;
    align-items: center;
    gap: .5em;
  }
}
