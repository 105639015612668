@import "scss/variablesV3";

.AppliedMarketplaceFilters {

}

.ChipContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: $spacing-sm;
  :global(.MuiLink-root) {
    cursor: pointer;
    margin-left: $spacing-sm;
  }
}

.FilterChip {
  & > :global(.MuiChip-label) {
    padding-left: ($spacing-paper / 2);
  }
}

.ClearButton {
  font-size: 12px;
}
