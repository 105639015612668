@import "scss/variables";

.IconLink {
  display: flex;
  align-items: center;
  fill: currentColor;
  text-decoration: none;
  font-size: 15px;
  transition: color $transition-default, fill $transition-default;
}

.link {
  margin-left: $spacing-md;
}
