@import "scss/variablesV3";

.PricingRule {
  h5 svg {
    vertical-align: baseline;
    margin-left: 0.25em;
  }
}

.Formula {
  :global(.MuiTypography-root) {
    line-height: 32px;
  }

  @include sm {
    display: flex;
    gap: $spacing-md;
  }

  @include xl {
    & > * {
      flex: 1 1 50%;
    }
  }
}

.FormulaPart {
  display: flex;
  align-items: baseline;
  gap: 0.85em;

  @include desktop {
    justify-content: space-between;
  }
}

.FormulaOperator {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 28px;
  line-height: 28px;
  font-weight: 100;
  transform: translateY(4px);
}
