@import "scss/variablesV3";

.Main {
  @include lg {
    display: grid;
    column-gap: $spacing-lg;
    row-gap: $spacing-lg;
    grid-template-columns: 50% 50%;
    grid-template-areas:
      "backbutton backbutton"
      "gallery    description"
      "supplier   description"
      "tabs       tabs"
      "more       more";
  }
}
