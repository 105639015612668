@import "scss/variablesV3";

.PricingRangeDisplay {
  font-size: 14px;
  font-weight: 400;

  &:not(:first-child) {
    margin-top: 2px;
  }
}
