.LoadingButton {
  :global(.MuiButton-label .MuiCircularProgress-root) {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -.75em 0 0 -.75em;
  }
  &.isLoading {
    :global(.MuiCircularProgress-root) + .LabelContent {
      visibility: hidden;
    }
  }
}
