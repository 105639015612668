@import "scss/variablesV3";


.Header {
  @include md {
    padding-bottom: 0;
  }
}

.Container {
  max-width: 1200px;
}
