@import "scss/variables";

.Link {
  display: inline-block;
  text-decoration: none;
  color: get-color('primary-700');
  transition: color $transition-default;

  &:hover {
    color: get-color('grey-400');
  }

  &.invert {
    color: get-color('blue-grey-4');

    &:hover {
      color: get-color('grey-100');
    }

    &.current {
      color: get-color('grey-50');
    }
  }

  &.block {
    display: block;
    padding: $spacing-xs 0;
  }

  &.current {
    color: get-color('grey-200');
  }
}
