@import "scss/variablesV3";

.SearchFilter {

}

.SearchField {
  flex-grow: 1;

  @include xl {
    flex-grow: 0;
  }
}
