@import "scss/variables";

.MoneyInput {
  :global(.MuiInputBase-input) {
    text-align: end;
  }

  :global(.MuiInputAdornment-root) {
    pointer-events: none;
  }
}
