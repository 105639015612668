@import "scss/variables";

.SimplePagination {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  :global(.MuiTypography-root) {
    margin-right: 0.5em;
  }
}
