@import "scss/variables";

.BusinessInfoPage {

}

.PageHeader {
  margin-bottom: $spacing-lg;
}

.Card {
  max-width: $screen-md;

  &:not(:last-child) {
    margin-bottom: $spacing-lg;
  }
}
