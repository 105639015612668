@import "scss/variablesV3";

.ImportListProductVariantImageSelector {

}

.Image {
  width: 150px !important;
  height: 150px !important;
}
