@import "scss/variablesV3";
@import "scss/mixins";

.MyStoreCard {
  :global(.MuiCardHeader-root) {
    padding-top: $spacing-sm;
    padding-right: $spacing-md;
  }
  :global(.MuiCardHeader-action) {
    margin-top: 0;
  }
  :global(.MuiListItemIcon-root) {
    min-width: 32px;
  }
  :global(.MuiCardActions-root) {
    justify-content: center;
  }

  &.isBeingUpdated {
    pointer-events: none;
    opacity: 0.5;
  }
}

.ConnectedPlatform {
  :global(.MuiTypography-body2) {
    height: 24px;
    & > * {
      vertical-align: middle;
    }
  }

  .WixLogo, .ShopifyLogo {
    transform: translateY(-1px);
  }
}

.StatsList {
  :global(.MuiListItemText-root) {
    flex-grow: 0;
    padding-right: $spacing-xs;
  }
}

.WarningIcon {
  color: #F44336;
}

.UpgradeCta {
  :global(.MuiTypography-root) {
    line-height: $spacing-md;
  }
}

.WixLogo {
  height: 12px;
}

.ShopifyLogo {
  height: 20px;
}

.MoreMenu {
  :global(.MuiMenu-paper) {
    overflow: visible;

    &:before {
      @include top-arrow();
    }
  }
}

.DestructiveActionMenuItem {
  color: get-color("error6")
}
