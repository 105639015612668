@import "scss/variables";

.MarketplaceItemGallery {

}

.SelectedImageContainer {
  position: relative;
  width: 100%;
  margin-bottom: $spacing-md;

  &.zoomable {
    cursor: zoom-in;
  }
}

.SelectedImageLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  z-index: 10;
  opacity: 1;
  transition: opacity .3s;

  &.hidden {
    opacity: 0
  }
}

.LoaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.SelectedImage {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 80vh;
  object-fit: cover;
  border-radius: $border-radius-default;
}

.Slider {
  flex-wrap: nowrap;
  // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
}

.ThumbnailButton {
  width: 100%;
  height: 100%;
  padding: 0;
  transition: all .3s;
  opacity: 0.5;

  :global(.MuiButton-label) {
    height: 100%;
  }

  &:hover,
  &.current {
    opacity: 1;
  }
}

.Thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $border-radius-default;
}

.focusBottom {
  object-position: bottom;
}

.Placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  background: get-color("grey-300");
}

.PlaceholderIcon {
  color: get-color("white");
  font-size: 64px;
}
