$palette: (
  "black": #000000,
  "white": #FFFFFF,
  "primary0": #F7F7FD,
  "primary5": #EBEBFA,
  "primary10": #D6D6F5,
  "primary15": #B1B1E7,
  "primary20": #7777D4,
  "primary30": #6464CE,
  "primary40": #4B4AC6,
  "primary50": #3535A7,
  "primary60": #31319B,
  "primary70": #252574,
  "primary80": #191966,
  "primary90": #18184E,
  "primary100": #0C0C27,

  "secondary0": #F6F7FE,
  "secondary5": #E8ECFD,
  "secondary10": #A1B4F7,
  "secondary15": #728EF3,
  "secondary20": #5B7BF1,
  "secondary30": #4368EF,
  "secondary40": #2C56ED,
  "secondary50": #1443EB,
  "secondary60": #1427B8,
  "secondary70": #0F1E8A,
  "secondary80": #0A145C,
  "secondary90": #080F45,
  "secondary100": #050A2E,

  "tertiary0": #FAF6FD,
  "tertiary5": #F3E9FB,
  "tertiary10": #DBBEF3,
  "tertiary15": #C294EB,
  "tertiary20": #AA69E2,
  "tertiary30": #923FDB,
  "tertiary40": #7F49D0,
  "tertiary50": #6931BE,
  "tertiary60": #5A2AA2,
  "tertiary70": #381A65,
  "tertiary80": #2D1551,
  "tertiary90": #22103D,
  "tertiary100": #180B28,

  "grey0": #F8FAFC,
  "grey5": #EFF2F7,
  "grey10": #BECBDA,
  "grey15": #91A6C0,
  "grey20": #647FA0,
  "grey30": #3E5878,
  "grey40": #324862,
  "grey50": #263950,
  "grey60": #1F2F42,
  "grey70": #0D213A,
  "grey80": #152138,
  "grey90": #09182A,
  "grey100": #02080E,

  "error1": #FBE5E5,
  "error2": #F5C2C2,
  "error3": #EC8D8E,
  "error4": #E45859,
  "error5": #DB2425,
  "error6": #C11F20,
  "error7": #A71B1C,
  "error8": #8C1718,
  "error9": #721313,

  "warning1": #FEEFC8,
  "warning2": #FBE19D,
  "warning3": #F7D069,
  "warning4": #F4BD2F,
  "warning5": #EDAC02,
  "warning6": #BB8802,
  "warning7": #886301,
  "warning8": #604601,
  "warning9": #382801,

  "success1": #E4F7D4,
  "success2": #C9EEB0,
  "success3": #9BD26A,
  "success4": #74AF41,
  "success5": #408305,
  "success6": #356D03,
  "success7": #295502,
  "success8": #1D3C01,
  "success9": #112301,

  "skeleton": #D9DBE3,
  "background": #EDEEF2,
);

@function get-color($color) {
  @return map-get($palette, $color)
}

$font-size-body1: 16px;
$font-size-body2: 14px;
$font-size-body3: 12px;

@import "common";
