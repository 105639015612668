@import "scss/variables";

.PageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Column {
  text-align: center;

  @include lg {
    text-align: left;
  }
}

.ErrorIcon {
  font-size: 12rem;
}

.BackButton {
  margin-top: $spacing-md;
}
