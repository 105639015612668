@import "scss/variables";

.MainMenuItem {
  .Link {
    display: flex;
    align-items: center;
    padding: $spacing-sm $spacing-md;
    height: 48px;
    color: get-color('blue-grey-3');
    &:hover {
      color: get-color('grey-50');
    }
    &[aria-current=page] {
      background: get-color('primary-900');
      color: get-color('grey-50');
    }
  }

  .Submenu {
    margin-bottom: $spacing-md;
    margin-left: 24px + $spacing-lg;
  }
}
