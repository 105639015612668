@import "scss/variables";

.Footer {
  margin-top: $spacing-md;
  padding: $spacing-md;

  .SocialLinks {
    margin-top: $spacing-md;
  }
}
