@import "scss/variables";

.OrDivider {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  &:after, &:before {
    content: "";
    display: block;
    flex: 1 1 auto;
    border: 0 solid get-color('grey-100');
    border-width: 0 0 1px 0;
  }
  span {
    flex: 0 0 auto;
    margin: $spacing-sm;
    font-size: 14px;
    color: get-color('grey-500');
    text-transform: uppercase;
  }
}

.Vertical {
  flex-flow: column nowrap;
  min-height: 100px;
  &:after, &:before {
    border-width: 0 1px 0 0;
  }
}
