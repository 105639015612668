@import "scss/variablesV2";

$editor-height: 120px;

.RichTextInput {

}

.Editor {
  flex: 1 1 auto;

  :global(.DraftEditor-root) {
    width: 100%;
    height: $editor-height;
    overflow-y: auto;
  }

  :global(.DraftEditor-editorContainer) {
    height: 100%;
  }

  > textarea {
    border: none;
    font-family: monospace;
    height: $editor-height;
  }
}

.Toolbar {
  display: flex;
  justify-content: space-between;
  margin: 0 -6px $spacing-md -6px;
}

.ToolbarGroup {
  padding: 0 6px;
}

.FormatControls {
  display: inline-flex;
}

.FormatButton {
  &.isActive {
    color: black;
  }
}

.BlockTypeSelect {
  width: 120px;
  font-size: $font-size-body2;
  line-height: 1;
  :global(.MuiSelect-selectMenu) {
    min-height: unset;
  }
}
