@import "scss/variables";

.Root {
}

.AlibabaLogo {
  display: block;
  width: 100%;
  height: auto;
  transform: translateY(-3px);  // adjust to baseline
}

.AliExpressLogo {
  display: block;
  height: 100%;
  width: auto;
}

.ConfirmJoin {
  @include lg {
    padding-right: $spacing-lg;
  }
}

.Intro {
  max-width: $screen-md;
}

.DefaultStoreSelect {
  max-width: 400px;
}
