@import "scss/variables";

.ActionButtons {
  justify-content: flex-end;
  margin-top: $spacing-md;
  @include md {
    margin-top: 0;
    width: auto;
  }
}

.Loader {
  margin-right: $spacing-sm;
}

.MoreButton {
  margin-left: $spacing-sm !important;
}

.MoreMenu {
  * {
    font-size: 14px;
  }

  .Divider {
    margin: $spacing-sm 0;
  }
}
