@import "scss/variables";

.Label {
  font-weight: 700;

  &.default {
    background: get-color("grey-200");
    color: get-color("grey-700");
  }

  &.success {
    background: get-color("success-50");
    color: get-color("success-600");
  }

  &.warning {
    background: get-color("warning-50");
    color: get-color("warning-600");
  }

  &.error {
    background: get-color("error-50");
    color: get-color("error-600");
  }
}
