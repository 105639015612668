@import "scss/variables";

.AddProductActionButton {
  .ButtonIcon {
    svg {
      fill: get-color('white');
    }
  }
}

.InteractiveWrapper {
  width: 100%;
}
