@import "scss/variablesV3";
@import "scss/mixins";

.Paper {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  max-height: calc(90vh - 60px);
  width: 400px;
  max-width: calc(100vw - 32px);
  overflow: visible;

  &:before {
    @include top-arrow();
  }
}

.Avatar {
  background-color: get-color("white");
  color: get-color("black");
}

.PremiumChip {
  background-color: get-color("tertiary20");
}

.Store {
  &.isPremium {
    background-color: rgba(get-color("secondary15"), $color-alpha-low);
  }

  :global(.MuiListItemText-multiline) {
    margin: 0;
  }

  :global(.MuiBox-root) > :global(.MuiSvgIcon-root) {
    display: block;
  }

  &:not(:last-child) {
    border-bottom: $border-divider;
  }
}

.StoreMenuHeader {
  padding-right: $spacing-sm;
  border-bottom: $border-divider;
}

.FooterButton {
  border-radius: 0;
  border-top: $border-divider;
}

.StoreButton {
  border-radius: 24px;
  :global(.MuiButton-startIcon) {
    margin-left: 0;
  }
}

.StoreButtonLabelText {
  max-width: 200px;
  @include text-overflow-ellipsis;
}
