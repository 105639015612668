@import "scss/variables";

.StarRating {
  .IconGridItem {
    line-height: 1 !important;
  }

  .Icon {
    font-size: 22px;
    color: get-color('warning-500');
    fill: get-color('warning-500');
  }
}
