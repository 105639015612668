@import "scss/variablesV3";

.ImportListProductDescriptionTab {
  padding: 24px 24px 12px 24px;
  height: 400px;
  overflow: auto;
}

$editor-height: 230px;

.Editor {
  :global(.DraftEditor-root),
  textarea {
    resize: none;
    width: 100%;
    height: $editor-height;
    padding: 0;
  }
}

.Counter {
  margin-top: $spacing-md;
  font-size: 14px;
  text-align: right;
  color: get-color("grey40");
}
