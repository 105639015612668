@import "scss/variables";

.TableContainer {
  margin: 0 (- $spacing-md);
  padding: 0 $spacing-md;
  box-sizing: content-box;
}

.OrderTable {
  th {
    font-size: 14px;
    color: get-color("grey-700");

    &:last-child {
      text-align: right;
    }
  }
  td {
    vertical-align: top;
    font-size: 14px;
    color: get-color("grey-600");

    &:last-child {
      text-align: right;
    }
  }
}

.ImageCol {
  width: 52px ;
}

.NameAndSkuCol {
  width: auto;
}

.VariantCol {
  width: 12%;
}

.ShippingMethodCol {
  width: 18%;
}

.SmallWidthCol {
  width: 10%;
}

.ImageCell {
  padding-left: 12px;
  padding-right: 0;
  text-align: center;
  img {
    max-width: 40px;
    max-height: 40px;
    object-fit: contain;
    border-radius: $border-radius-default;
  }
}

.Summary {
  padding-top: $spacing-md;
  border-top: 1px solid get-color("grey-200");
  color: get-color("grey-600");

  @include lg {
    padding-top: 0;
  }

  td {
    padding-top: $spacing-md;
    font-weight: 700;
  }

  .Shipping {
    margin-bottom: $spacing-xs;
  }

  .ShippingSummary {
    text-align: end;
  }

  .ShippingMethod {
    font-weight: normal;
    margin-inline-start: 1em;
  }

  .TotalColumn {
    min-width: 100px;
  }

  .Total {
    font-weight: 700;
  }
}
