@import "scss/variablesV3";

.ShippingCountrySelect {
  &:global(.MuiInput-underline):before {
    display: none;
  }
}

.ShippingTime {
  margin-left: $spacing-xs;
  font-size: $font-size-body3;
  line-height: 16px;
}

.ThirdPartyShipping {
  font-size: $font-size-body3
}

.UsShippingFlag {
  padding-right: 10px;
  box-shadow: none;
}
