@import "scss/variablesV3";

.ImportListProductTableField {
  background: get-color("white");
  color: inherit;

  :global(.MuiFormHelperText-root) {
    display: none !important;
  }

  :global(.MuiInputAdornment-root) {
    font-size: 14px;
    color: get-color("grey40");
  }

  :global(.Mui-disabled) {
    pointer-events: none;
  }
}

.Input {
  font-size: 14px;
  background: get-color("white");
  color: get-color("grey40");
}

.HeadInput {
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 700;
  color: get-color("grey70");
}

.Error {
  color: get-color("error5");
  font-size: 20px;
  opacity: 1;
}
