@import "scss/variablesV3";

.FiltersDrawerSection {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  .SectionContent {
    flex-grow: 1;
  }
}

.SectionHeader {
  height: 56px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  :global(.MuiSvgIcon-root) {
    margin-left: -6px;
    transform: translateY(-1px);
  }
}
