@import "scss/variables";

.ArchiveListFilters {

}

.Input {
  background: get-color("white");
}

.RestorableSwitcher {
  margin: $spacing-md 0;
  text-align: right;
}
