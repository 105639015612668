@import "scss/variables";

.InventoryTypeBadge {

  :global(.MuiChip-avatar) {
    background: rgba(0, 0, 0, 0.5) !important;
  }

  &.collapsed {
    :global(.MuiChip-label) {
      padding-right: 0;
    }
  }
}
