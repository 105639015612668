@import "scss/variablesV3";

.Alerts {
  z-index: 100;
}

.AlertActionButton {
  background: #FFF;
  border-color: #F5F6F6;
  white-space: nowrap;
  flex: 0 0 auto;
}

.Alert {
  border-radius: 0;

  :global(.MuiAlert-message) {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    gap: $spacing-sm;
  }

  @include sm {
    justify-content: center;

    :global(.MuiAlert-message) {
      flex-flow: row nowrap;
      align-items: center;
      margin: 0 auto;
    }

    :global(.MuiAlert-action) {
      margin-left: 0;
    }
  }

  &:global(.MuiAlert-filledWarning) .AlertActionButton {
    color: get-color("warning5");
  }

  &:global(.MuiAlert-filledInfo) .AlertActionButton {
    color: get-color("secondary30");
  }

  &:global(.MuiAlert-filledSuccess) .AlertActionButton {
    color: get-color("success5");
  }

  &:global(.MuiAlert-filledError) .AlertActionButton {
    color: get-color("error5");
  }
}
