@import "scss/variables";

.RetailerPricingRuleHelper {

}

/**
 * Make links within tooltips bold.
 */
:global(.MuiTooltip-popper) {
  .Link {
    color: inherit;
    font-weight: 700;
  }
}
