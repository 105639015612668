.DataModal {
}

.ModalTitle {
  z-index: 10
}

.ModalTitleCloseButton {
}

.ProgressBar {
  height: 4px;
  margin-bottom: -4px;
  transform: translateY(-4px);
  transition: transform .2s ease-in-out;
  z-index: 9;

  &.isActive {
    transform: translateY(0);
  }
}

.ModalContent {
  position: relative;
  z-index: 8;
  &.isLocked {
  }
}

.ModalActions {
}
