@import "scss/variablesV3";

.SyncListItem {
  background: get-color("white");
  transition: background .2s;

  :global(.MuiTableCell-body) {
    vertical-align: top;
    color: get-color("grey30");
  }

  &.exportInProgress {
    opacity: 0.8;
  }
}

.Section {
  margin-top: $spacing-md;
}

.Checkbox {
  margin-left: -5px;
}

.ProductCol {
  @include xl {
    min-width: 350px;
    max-width: 350px;
  }
}

.ProductGrid {
  flex-wrap: nowrap;
  align-items: flex-start;
}

.ProductInfo {
  overflow: hidden;
}

.ProductName {
  display: block;

  @include xl {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.style_commerce {
  object-position: bottom;
}

.PricingCell {
  min-width: 300px;
}

.Price {
  font-size: 14px;
  font-weight: 400;

  &:not(:first-child) {
    margin-top: 2px;
  }
}

.ProfitBlock {
  display: flex;
  align-items: center;
}

.ActionsColumn {
  vertical-align: middle !important;
}

.Actions {
  justify-content: flex-end;
  align-items: center;
}

.Prominent {
  font-weight: 700;
}

.negative {
  color: mix(get-color("warning5"), get-color("error5"), 70%);
}

.error {
  color: get-color("error5");
}

.Status {
  margin-top: $spacing-xs;
}
