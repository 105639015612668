@import "scss/variablesV3";

.ImportListProductVariantsTab {
  height: 400px;
  overflow: auto;
}

.Loader {
  padding: 24px;
  height: 400px;
}

.ImageCell {
  width: 48px;
}

.Image {
  width: 48px !important;
  height: 48px !important;
}

.Variant {
  transition: opacity .2s;

  &.inactive {
    opacity: 0.3;
  }
}

.MissingOption {
  pointer-events: none;
}

.Alert {
  border-radius: 0;
}
