@import "scss/variables";

.Header {
  margin-top: $spacing-md;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: get-color("grey-700");
}

.CurrentSubscriptionPlan {
  &::after {
    display: block;
    width: 100%;
    height: 2px;
    content: "";
    background: get-color("grey-200");
    margin: $spacing-lg 0;
  }
}
