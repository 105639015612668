@import "scss/variablesV3";

.PricingRulesContent {
  :global(.MuiCard-root) {
    margin-bottom: $spacing-paper;
  }

  :global(.MuiTypography-h4) {
    font-size: 20px;
    font-weight: 500;
  }

  :global(.MuiTypography-h5) {
    font-size: 18px;
    font-weight: 400;
  }
}
