@import "scss/variables";

.FiltersPanelSection {
  box-shadow: none;
}

.Header {
  min-height: 42px;
  max-height: 42px;

  &:global(.Mui-expanded) {
    min-height: 42px;
    max-height: 42px;
    margin: 0;
  }

  :global(.MuiAccordionSummary-content) {
    margin: 0;
  }
}

.Title {
  font-size: 16px;
  font-weight: 700;
}
