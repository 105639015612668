@import "scss/variables";

.Root {
  flex: 1 1 1%;
}

.Label {
  margin-top: 16px;
}

.IconWrapper {
  padding: $spacing-md;
  background: rgba(get-color('primary-400'), 0.2);
  border-radius: 100%;
  > svg {
    display: block;
    font-size: inherit;
  }
}

.RadioTile {
  position: relative;
  margin: 0;
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.23);
  padding: 24px 8px 16px;
  border-radius: $border-radius-default;
  transition: border .2s;

  &:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }

  &:before {
    content: "";
    position: absolute;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23FFF" d="M10.75 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z"/></svg>');
    background-color: get-color('primary-600');
    top: 24px;
    right: -12px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    transform: scale(0);
    opacity: 0;
    transition: all 0.25s cubic-bezier(.47,.25,.15,1.43);
  }

  &.Checked {
    border-color: get-color('primary-600');
    &:before {
      top: -12px;
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }

  &.Small {
    padding: 16px 8px 8px;
    .Label {
      margin-top: 8px;
    }
  }
}
