@import "scss/variables";

.OrderItemShippingCostRoot {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;

  > :not(:last-child) {
    margin-right: .5em;
  }
}

.NoShippingMethod {
  color: get-color('error-600');
}
