@import "scss/variables";

.MarketplaceItemHeader {

}

.Flag {
  height: 14px;
  margin-right: $spacing-sm;
}

.BrandName {
  font-size: 16px;
  font-weight: 500;
}

.SKU {
  font-size: 14px;
  font-weight: 700;
}

.InventoryType {
  font-size: 15px;
  font-weight: 700;
}

.Title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.ExternalLink {
  margin-left: $spacing-sm;
}
