@import "scss/variablesV3";

$line-height: 20px;
$font-size-small: 12px;

.CustomizableProductSlate {
  position: relative;
  overflow: visible;
  font-size: 14px;
  line-height: $line-height;
  outline-color: transparent;
  transition: outline-color .2s ease-out;

  :global(.MuiCardContent-root) {
    padding: $spacing-sm ($spacing-paper / 2) ($spacing-paper / 2);
  }

  :global(.MuiCardMedia-root) {
    font-size: inherit;
    background-color: get-color("skeleton");
  }

  > a {
    display: block;
    overflow: hidden;
    border-radius: inherit;
    color: inherit;
    text-decoration: unset;
  }

  .Actions {
    bottom: 173px;
    background-color: transparent;
  }
}

.CardMedia {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.ProductImage {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0;
  transition: opacity 0.2s;

  &.ProductImageVisible {
    opacity: 1;
    transition: opacity 0.5s;
    background-color: inherit;
  }
}

.ProductName {
  font-weight: 700;
}

.ProductCost, .Sizes  {
  font-size: $font-size-small;
}

.Sizes {
  &:empty:after {
    content: "\00a0";
  }
}
