@import "scss/variablesV3";

.PricingRulesCard {

}

.Description {
    padding-top: $spacing-lg;
    padding-bottom: $spacing-lg;

    :global(.MuiTypography-h5) {
        line-height: 24px;
    }

    p:first-child {
        margin-top: 4px;
    }

    p:last-child {
        margin-bottom: 4px;
    }
}

.InventoryTypeTabs {
    :global(.MuiTab-root) {
        min-width: 0;
        padding-left: $spacing-md;
        padding-right: $spacing-md;
    }
}

.OverrideShippingRate {
    :global(.MuiInputBase-root.Mui-disabled) {
        opacity: 0.5;
    }
}
