$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-2xl: 1420px;
$screen-3xl: 1900px;
$screen-4xl: 2540px;
$screen-5xl: 2800px;

@mixin size-up($size) {
   @media (min-width: #{$size}) { @content; }
}

@mixin size-down($size) {
   @media (max-width: #{$size - 1px}) { @content; }
}

@mixin sm {
   @media (min-width: #{$screen-sm}) { @content; }
}

@mixin md {
   @media (min-width: #{$screen-md}) { @content; }
}

@mixin lg {
   @media (min-width: #{$screen-lg}) { @content; }
}

@mixin xl {
   @media (min-width: #{$screen-xl}) { @content; }
}

@mixin xxl {
   @media (min-width: #{$screen-2xl}) { @content; }
}

@mixin xxxl {
   @media (min-width: #{$screen-3xl}) { @content; }
}

@mixin xxxxl {
   @media (min-width: #{$screen-4xl}) { @content; }
}

@mixin xxxxxl {
   @media (min-width: #{$screen-5xl}) { @content; }
}

@mixin desktop {
   @include lg { @content; };
}

$spacing-paper: 24px;
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 32px;

$transition-fast: 0.1s;
$transition-default: 0.2s;
$transition-slow: 0.5s;

$border-radius-small: 2px;
$border-radius-default: 4px;
$border-radius-large: 8px;

$border-divider: 1px solid rgba(0,0,0,.12);

$color-alpha-high: 0.87;
$color-alpha-medium: 0.54;
$color-alpha-low: 0.25;
