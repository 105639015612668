@import "scss/variablesV3";
@import "scss/mixins";

.DrawerPaper {
  width: 100%;
  max-width: 300px;
  background: get-color("grey70");
  @include desktop { max-width: 240px; }

  &.isPermanent {
    top: 60px;
    height: calc(100vh - 60px);
  }

  @include styled-scrollbar(get-color('primary20'), get-color('grey90'));
}
