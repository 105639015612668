@import "scss/variables";

.OrdersFilters  {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin-top: $spacing-md;
  width: 100%;

  @include lg {
    flex-direction: row;
  }

  > *:not(:last-child) {
    margin-bottom: $spacing-md;

    @include lg {
      margin-bottom: 0;
      margin-right: $spacing-md;
    }
  }

  .Input {
    background: get-color("white")
  }

  .Search {
    @include lg {
      flex-basis: 50%;
    }
  }

  .Filter {
    flex: 1;
  }
}
