@import "scss/variables";

.OrderCancelModal {
  .ModalContent {
    margin-top: $spacing-lg;
  }
  .Items {
    margin-top: $spacing-md;
  }
}
