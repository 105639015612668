@import "scss/variablesV3";
@import "scss/mixins";

.CtaCard {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  background-color: var(--bg-color);
  border-radius: 0;
  box-shadow: none;

  &.divided {
    flex-flow: row-reverse nowrap;
    .CardMedia {
      position: relative;
      flex: 0 0 50%;
      &:before {
        content: "";
        display: block;
        @include fill-parent;
        width: 50%;
        background: linear-gradient(to right, var(--bg-color) 0%, transparent 100%);
      }
    }
    .CardText {
      flex: 0 0 50%;
      padding-right: 0;
    }
  }

  &.overlaid {
    .CardMedia {
      position: absolute;
      @include fill-parent;
      &.darken:before {
        content: "";
        display: block;
        @include fill-parent;
        background-color: rgba(0, 0, 0, $color-alpha-low);
      }
    }
  }
}

.CardText {
  position: relative;
  height: 100%;
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: $spacing-paper;
  color: get-color("white");

  @include xl {
    padding: $spacing-paper (2 * $spacing-paper);
  }

  & > * {
    position: relative;
  }

  :global(.MuiTypography-h4) {
    font-size: 32px;
  }

  // left - default
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  &.top, &.center, &.bottom {
    align-items: center;
    text-align: center;
  }
  &.topRight, &.right, &.bottomRight {
    align-items: flex-end;
    text-align: right;
  }
  &.topLeft, &.top, &.topRight {
    justify-content: flex-start;
  }
  &.bottomLeft, &.bottom, &.bottomRight {
    justify-content: flex-end;
  }
}

.CardMedia {
  background-size: cover;
  background-position: top;
}

.GoButton {
  color: get-color("white");
  border: 2px solid get-color("white");
  border-radius: 21px;

  &:global(.MuiButton-root) {
    padding: 0;
  }
  :global(.MuiButton-label) {
    gap: $spacing-md;
    padding-inline-start: $spacing-md;
    font-weight: 700;
  }

  &.iconOnly, :global(.MuiButton-endIcon) {
    background-color: rgba(get-color("white"), $color-alpha-low);
    border: 2px solid get-color("white");
    border-radius: 21px;
    padding: $spacing-sm;
    :global(.MuiSvgIcon-root) { font-size: 22px; }
  }
  :global(.MuiButton-endIcon) {
    margin: -2px;
  }
}
