@import "scss/variables";

.MarketplaceItemTabs {

}

.Content {
  padding-top: $spacing-lg * 2 !important;
  padding-bottom: $spacing-lg * 2 !important;
}

.TabHeader {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include lg {
    flex-direction: row;
  }

  .Icon {
    margin-bottom: $spacing-xs;

    @include lg {
      margin-bottom: 0;
      margin-right: $spacing-sm;
    }
  }
}
