@import "scss/variables";

.InputWithIcon {
  position: relative;

  .Input input {
    padding-left: 32px;
  }

  .Icon {
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    left: $spacing-sm;
    top: 50%;
    transform: translateY(-50%);
    color: get-color(grey-600);

    svg {
      width: 18px;
      height: 18px;
    }
  }
}
