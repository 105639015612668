@import "scss/variablesV2";

.FileUploadArea {
  border: 2px dashed get-color("blueGrey-200");
  color: get-color("blueGrey-400");
  flex-direction: column;
  padding: $spacing-sm;
  > * {
    pointer-events: none;
  }
  > :global(.MuiTypography-root) {
    padding: $spacing-sm 0 $spacing-xs;
  }
  &.isDragging {
    border-color: get-color("primary-500");
    color: get-color("primary-500");
  }
  &.isFullWidth {
    width: 100%;
  }
}
