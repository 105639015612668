@import "scss/variables";

.OrderItemShippingMethodModal {
}

.OrderItemShippingMethodModalBody {
}

.Information {
  margin: 12px 0 24px;
}

.ErrorContainer {
  margin: 24px 0;
}

.ShippingMethodsTableContainer {
  margin-top: 24px;
}

.ShippingMethodsTable {
  tr > th:first-child {
    padding-left: 0;
  }
  tr > td:first-child {
    padding-left: 12px;
  }
  tr > :last-child {
    padding-right: 0;
  }
}

.RadioLabel {
  font-size: 13px;
}
