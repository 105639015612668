@import "scss/variablesV3";

.ImportListProductShippingSelector {
  :global(.MuiDialogContent-root) {
    padding-left: 0;
    padding-right: 0;
  }
}

.PadBox {
  padding-left: 24px;
  padding-right: 24px;
}

.ShippingCountry {
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 1px solid get-color("grey5");
  line-height: 1;
}

.ShippingCountryName {
  font-size: 14px;
  font-weight: 700;
  color: get-color("grey50");
}

.Table {
  margin: 12px 0;
}

.TableHead {
  :global(.MuiTableCell-root) {
    background: get-color("primary0");
    color: get-color("grey50");
    font-size: 12px;
    font-weight: 700;
    padding: 10px 16px;
  }
}

.RadioCell {
  width: 50px;
}
