@import "scss/variablesV3";

.ImportListProductMediaTab {
  display: flex;
  flex-direction: column;
  height: 400px;
  overflow: auto;
}

.Alert {
  border-radius: 0;
}

.Gallery {
  flex-grow: 1;
  padding: 24px 24px 12px 24px;
}

.Counter {
  margin-top: $spacing-md;
  font-size: 14px;
  text-align: right;
  color: get-color("grey40");
}
