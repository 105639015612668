@import "scss/variables";

.MarketplaceItemPricing {
  & > :global(.MuiCardContent-root) {
    padding: $spacing-paper;
  }
}

.PriceTag {
  font-weight: 500;

  &.cost {
    font-weight: 700;
  }

  &.positive {
    color: get-color("success-600");
  }

  &.neutral {
    color: get-color("black")
  }

  &.negative {
    color: get-color("error-600");
  }
}

.HelperContent {
  margin-top: $spacing-sm;
}

.TooltipHelper {
  width: 18px;
  height: 18px;
  margin-left: $spacing-xs;
  transform: translateY(-1px);
}

.Centered {
  display: flex;
  align-items: center;
}
