@import "scss/variables";

.ImageUpload {

}

.Icon {
  font-size: 3rem;
}

.DragIndicator {
  color: get-color('blue-grey-4');
}

.LogoLabel {
  display: block;
  margin-left: 16px;
  margin-bottom: 8px;
  font-size: 12px;
}

.Image {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-default;
  object-fit: contain;

  &.unfilled {
    color: get-color('white') !important;
    background: get-color('grey-100')
  }
}

.DeleteButton {
  margin-left: 8px;
  margin-top: 8px;
}
