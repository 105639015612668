@import "scss/variables";

.MarketplaceItemDataRow {
  margin: $spacing-md 0;
}

.Title {
  font-size: 14px;
  font-weight: 700;

  @include xl {
    max-width: 200px;
  }
}

.Content {
  font-size: 14px;
}
