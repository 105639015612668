@import "scss/variables";

.FeaturesTable {
  margin-top: $spacing-lg * 2;
}

.Heading {
  font-size: 20px;
  font-weight: 700;
}

.Table {

}

.Row {
  margin-top: $spacing-md;
}

.Column {
  text-align: center;
}

.FeatureName {
  padding: $spacing-sm 0;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: get-color("grey-700");
  border-bottom: 1px solid get-color("grey-400");
}

.Check {
  color: get-color("primary-500");
}
