@import "scss/variables";

.PlanUpgradeModal {

}

.HeadingArea {
  margin: $spacing-md 0;
  padding: $spacing-md;
  background: get-color("primary-100");
  border-radius: $border-radius-default;
}

.Title {
  margin-top: $spacing-lg;
  margin-bottom: $spacing-md;
  font-size: 20px;
  font-weight: 700;

  &.noMargin {
    margin-top: 0;
  }
}

.PlanName {
  @extend .Title;
  margin-top: $spacing-md;
  margin-bottom: $spacing-sm;
}

.PromoCode {
  &.PromoCodeSuccess {
    background: get-color("success-50");
    color: get-color("success-500");
  }
}

.PromoCodeButton {
  background: get-color("warning-400");

  &:hover {
    background: get-color("warning-600");
  }
}

.SubmitInput {
  display: none;
}

.Error {
  margin: $spacing-md 0;
}

.Success {
  margin: $spacing-md 0;
}

:global(.recurly-element) {
  height: 54px;
  margin: 0;
  padding: 0 $spacing-sm;
  border: 1px solid get-color("grey-300");
  border-radius: $border-radius-default;

  &:hover {
    border: 1px solid get-color("grey-800");
  }
}

:global(.recurly-element-focus) {
  border-color: get-color("primary-500");
  box-shadow: inset 0 0 0 1px get-color("primary-500");
}

:global(.recurly-element-invalid) {
  border-color: get-color("error-500");
  box-shadow: inset 0 0 0 1px get-color("error-500");
}

:global(.recurly-element-card) {
  font-size: 20px;
}

.RecurlyError {
  :global(.recurly-element) {
    border-color: get-color("error-600");
  }
}

.RecurlyErrorHelper {
  margin-left: $spacing-md;
}

.RadioGroup {
  justify-content: space-between;
  margin: -8px;

  > * {
    margin: 8px;
  }
}

.RadioGroupItem {
  flex: 1 1 0;
  padding: $spacing-md;
  border: 1px solid get-color("grey-200");
  border-radius: $border-radius-default;
}

.Label {
  margin-top: $spacing-md;
  text-align: center;
}

.LabelIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
}

.ReuseBillingInfo {
  margin-bottom: $spacing-md;
}
