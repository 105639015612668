@import "scss/variables";

.ArchiveListItem {
  padding: $spacing-md;
  transition: background .2s;

  &.marked {
    background: get-color("primary-100");
  }

  @include md {
    padding: $spacing-md $spacing-sm;
  }

  @include lg {
    padding: $spacing-sm;
  }
}

.Checkbox {
  align-self: flex-start;

  @include lg {
    align-self: center;
  }
}

.ProductDetails {
  display: flex;
  align-items: center;
}

.Image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: $spacing-md;
  border-radius: $border-radius-default;

  &.style_commerce {
    object-position: bottom;
  }
}

.Product {
  > * {
    display: block;
  }
}

.InventoryType {
  margin-right: 12px;
}

.ProductName {
  font-size: 14px;
  font-weight: 700;
}

.BrandName {
  font-size: 14px;
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Badges {
  > * {
    display: flex;
    width: fit-content;
  }

  > *:not(:last-child) {
    margin-bottom: $spacing-sm;
  }
}
