@import "scss/variables";

.ModalystOrderPaymentModal {

}

.ModalystOrderPaymentModalBody {

}

.PaymentProcessorInformation {
  margin: 12px 0 24px 0;
}

.ErrorContainer {
  margin: 24px 0;
}

.ItemsTableContainer {
  margin-top: 24px;
}

.ItemsTable {
  tr > :first-child {
    padding-left: 0;
  }
  tr > :last-child {
    padding-right: 0;
  }
  td {
    border-bottom: none;
  }
  tfoot > tr > td {
    font-size: 13px;
    border-bottom: none;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  tfoot > tr:first-child > td {
    padding-top: 1em;
  }
}
