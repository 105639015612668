@import "scss/variablesV3";

.CategoryTabs {
  min-height: unset;

  :global(.MuiTabs-flexContainer) {
    gap: 1px;
    @include lg {
      position: relative;
      z-index: 1291; // raise tabs over category picker
    }
  }

  :global(.MuiTabs-indicator) {
    display: none;
  }

  :global(.MuiTab-root) {
    min-width: unset;
    min-height: unset;
    border-radius: $border-radius-default $border-radius-default 0 0;
    transition: background-color .1s linear, color .1s linear, opacity .1s linear;
  }

  :global(.MuiTab-root.Mui-selected) {
    background-color: get-color("background");
    color: get-color("primary40")
  }

  :global(.MuiTab-root).isActive {
    background-color: get-color("white");
    color: get-color("primary40");
    opacity: 1;
  }

  &.pickerVisible {
    :global(.MuiTab-root.Mui-selected):not(.isActive) {
      opacity: 0.75;
    }
  }
}

.CategoryPicker {
  // lower the index so that we can place the navbar over it, but not risk
  // other popovers being covered by the navbar
  z-index: 1290 !important;
}
