@import "scss/variables";
@import "scss/mixins";

.UserProfileMenu {
  :global(.MuiMenu-paper) {
    overflow: visible;
    &:before {
      @include top-arrow();
    }
  }
}
