@import "scss/variables";

.BatchProductListActions {
  width: fit-content;
  align-items: center;
  padding: 0 $spacing-xs;
  border: 1px solid get-color("grey-300");
  border-radius: $border-radius-default;

  @include md {
    height: 60px;
  }

  > * {
    margin: $spacing-xs;
  }

  > .Divider {
    margin: 0 $spacing-xs;
  }
}

.Divider {
  width: 100%;
  color: get-color("grey-300");

  @include md {
    width: 1px;
  }
}

.Checkbox {
  :global(.MuiFormControlLabel-label) {
    font-size: 14px;
    line-height: 1;
    color: get-color("grey-800");
  }

  &.selected {
    :global(.MuiFormControlLabel-label) {
      margin: 2px 8px 0 0;
    }
  }
}

.SelectAllBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing-sm $spacing-md;
  background: transparent;
  border: 1px solid get-color("grey-50");
  background: get-color("grey-50");
  border-radius: $border-radius-default;
  font-size: 15px;

  @include md {
    flex-direction: row;
  }

  @include lg {
    height: 60px;
  }

  > *:not(:last-child) {
    display: block;
    margin-right: $spacing-sm;
  }
}
