@use "sass:color";

@import "scss/variables";

.SubscriptionPeriodSwitcher {

}

.Button {
  font-weight: 700;
  padding-left: 32px;
  padding-right: 32px;
  transition: background .2s, color .2s;

  &:global(.Mui-selected) {
    color: get-color("primary-500");
    background-color: rgba(get-color("primary-500"), 0.2);

    &:hover {
      background-color: rgba(get-color("primary-500"), 0.3);
    }
  }
}
