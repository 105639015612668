@import "scss/variables";

.PublicInfoForm {

}

.Input {
  width: 100%;
  margin: 0;

  &.SmallInput {
    @include md {
      max-width: $screen-md;
    }
  }
}

.Errors {
  margin-bottom: $spacing-lg;
}

.LogoSection {
  margin-bottom: $spacing-lg;
  padding: $spacing-md;
  border-radius: $border-radius-default;
  border: 1px solid get-color("grey-200");
}
