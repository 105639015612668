@import "scss/variables";

.ManualOrderVariant {
  position: relative;
  padding: $spacing-md;
  background: get-color("grey-50");
  transition: opacity .3s, filter .3s;

  &.added {
    background: get-color("primary-100");
  }

  &.disabled {
    filter: grayscale(1);
    opacity: 0.7;
  }
}

.RemoveButton {
  position: absolute;
  top: -12px;
  right: -12px;
  background: get-color("white");
  border: 2px solid get-color("grey-700");
  color: get-color("grey-700");
  transition: background .3s;

  &:hover {
    background: get-color("primary-100");
  }
}

.ItemName {
  font-weight: 700;
}

.Price {
  display: block;
  font-size: 15px;

  @include md {
    text-align: right;
  }
}

.Sum {
  font-weight: 700;
}

.ImageWrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.Image {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $border-radius-default;
}
