@import "scss/variables";

.ManuallyOrderItemModal {

}

.About {
  margin: $spacing-md 0 $spacing-lg 0;
}

.Summary {
  margin-top: $spacing-md;
  text-align: right;
}

.Cost {
  font-size: 15px;
}

.Shipping {
  font-size: 13px;
}

.Total {
  margin-top: $spacing-sm;
  font-size: 15px;
  font-weight: 700;
}

.Alert {
  margin: $spacing-md 0;
}
