@import "scss/variables";

.Wrapper {
  width: 100%;
  margin: $spacing-md 0;
  padding: 0;
  background: transparent;
  border-color: get-color("accent-2");
  color: get-color("grey-300");

  &:disabled {
    border-color: get-color("accent-2");
    color: get-color("grey-300");
  }

  &:hover {
    background: rgba(get-color("white"), 0.05);
  }
}

.RetailerSubscriptionBox {
  padding: $spacing-sm $spacing-md ($spacing-sm + 3px) $spacing-md;
  text-align: center;

  .Loader {
    width: 100%;
    height: 0.5rem;
    margin: $spacing-lg 0;
    background-color: darken(get-color("primary-800"), 2%);

    :global(.MuiLinearProgress-bar) {
      background-color: lighten(get-color("primary-800"), 0.5%);
    }
  }

  .PlanName {
    font-size: 15px;
    font-weight: 700;
    color: get-color("grey-50");
  }

  .LineElement {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $spacing-xs;
    font-weight: 700;
  }

  .Warning {
    margin-top: $spacing-sm;
    color: get-color("warning-500");
  }

  .WarningIcon {
    margin-right: $spacing-xs;
  }

  .ArrowIcon {
    margin-left: $spacing-xs;
  }

  .Button {
    margin-top: $spacing-xs;
    color: get-color("white");
  }
}
