@import "scss/variablesV3";

.SidebarWithHeaderTemplate {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include md {
    flex-direction: row;
    align-items: stretch;
  }
}

.MainContainer {
  width: 100%;
  flex-grow: 1;

  @include desktop {
    margin-top: 0;
    padding-left: 240px;
  }
}

.Header {
  padding: $spacing-md;
  @include md {
    padding: $spacing-paper $spacing-lg;
  }
  background-color: rgba(get-color("primary40"), 0.8);
  color: get-color("white");
}

.Main {
  margin-bottom: 85px; // Leave some space for the Intercom floating button...
  padding: $spacing-md;

  @include md {
    padding: $spacing-lg;
  }
}
