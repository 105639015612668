@import "scss/variablesV3";

.MoreButton {
  width: 40px;
  height: 40px;
  background: get-color("primary5");

  &:hover {
    background: get-color("primary10");
  }
}
