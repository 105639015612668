@import "scss/variables";

.AppBar {
  z-index: 1300;
}

.LogoLink {
  &, & > svg { display: block; }
  transform: translateY(-2px);
}

.SignLogo {
  height: 22px;
}
