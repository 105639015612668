@import "scss/variables";

.ExternalSourceLink {
  min-width: 0;
  padding: 4px 6px !important;

  :global(.MuiButton-startIcon) {
    margin-right: 4px;
  }

  :global(.MuiButton-endIcon) {
    margin-left: 4px;
  }
}
