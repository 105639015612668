@import "scss/variables";

.MainMenu {
  margin-top: $spacing-sm;

  .WithChip {
    display: flex;
    align-items: center;
  }

  .Chip {
    height: auto;
    margin-left: $spacing-sm;
    padding: 2px 4px;
    font-size: 9px;

    :global(.MuiChip-label) {
      padding: 0;
    }
  }

  .Badge {
    :global(.MuiBadge-badge) {
      font-size: 10px;
    }
  }
}

.Submenu {
  font-size: 14px;
  a {
    display: block;
    text-decoration: none;
    padding: $spacing-xs 0;
    transition: color $transition-default;
    color: get-color('blue-grey-4');

    &:hover { color: get-color('grey-100'); }
    &:global(.active) { color: get-color('grey-200'); }
  }
}
