@import "scss/variablesV3";

$light-bg-color: mix(get-color("secondary0"), get-color("secondary5"));

@keyframes pulse {
  0% { opacity: 0.2; }
  50% { opacity: 0.7; }
  100% { opacity: 0.2; }
}

.ImportListProduct {
  position: relative;
  margin-top: 24px;
  border-radius: $border-radius-default;
  overflow: hidden;

  &.unavailable {
    box-shadow: 0 0 0 2px get-color("error2");
  }

  &.removing {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: transparentize(get-color("grey20"), 0.5);
      content: "";
      z-index: 10;
      animation: pulse 2s infinite ease-in-out;
    }
  }
}

.HeadWrapper {
  background: $light-bg-color;
}

.Head {
  padding: 12px 24px 14px 24px;
  background: get-color("white");
  //border-radius: $border-radius-default;
  box-shadow: inset 0px -1px 0px mix(get-color("primary5"), get-color("primary10"));

  > * {
    height: 100%;
  }
}

.HeadImage {
  width: 72px !important;
  height: 72px !important;
}

.Tabs {
  background: $light-bg-color;
  box-shadow: inset 0px -1px 0px mix(get-color("primary5"), get-color("primary10"));
}

.TabLabel {
  min-height: 48px;
  padding-top: 6px;

  :global(.MuiTab-wrapper) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.TabError {
  margin-top: -2px;
  margin-bottom: 0 !important;
  margin-right: $spacing-xs;
  color: get-color("warning5");

  &.blocking {
    color: get-color("error5");
  }
}

.Body {
}

.ProductInfo {
  color: get-color("grey30");
  min-width: 0;
}

.ProductName {
  display: inline-block;
  margin-top: 2px;
  color: get-color("grey40");
  max-width: 100%;

  &:hover {
    color: get-color("grey60");
    text-decoration: underline;
  }

  :global(.MuiTypography-body2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
  }
}

.PricingRanges {
  margin-top: -6px;
  margin-bottom: -2px;
}

.TabContent {
  padding: 24px;
}

.RemoveAction {
  color: get-color("error6");
}

.CustomizeButton {
  background: get-color("primary5");
  color: get-color("primary50");

  &:hover {
    background: get-color("primary10");
  }
}

.style_commerce {
  object-position: bottom;
}

.negative {
  color: mix(get-color("error5"), get-color("warning5"), 25%);
}

.MainButton {
  background-color: get-color("primary40");
  font-weight: 500;
}
