@import "scss/variables";

.SubscriptionPlanCard {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: visible;
  border-radius: $border-radius-default;

  &.current {
    box-shadow: 0 0 0 2px get-color("primary-500");
  }

  &.disabled {
    filter: grayscale(100%);
  }

  .CurrentIndicator {
    position: absolute;
    top: -24px;
    left: 0;
    font-size: 12px;
    font-weight: 700;
    color: get-color("primary-700");
    text-align: left;
    opacity: 0;
    transition: opacity .2s;
  }

  &:hover {
    .CurrentIndicator {
      opacity: 1;
    }
  }
}

.FeaturedIndicator {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-$spacing-md, -50%);
}

.Header {
  border-radius: $border-radius-default $border-radius-default 0 0;
  background: get-color("primary-500");
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  > * {
    flex: 1 1 0;
  }
}

.Title {
  margin-bottom: $spacing-sm;
  color: get-color("white");
  font-weight: 700;
}

.Subtitle {
  color: rgba(get-color("white"), 0.8);
  font-size: 13px;
}

.Price {
  margin: $spacing-md 0;
  line-height: 1;

  .PriceAmount {
    font-size: 32px;
    font-weight: 700;
    color: get-color("grey-700");
  }

  .PricePeriod {
    font-size: 16px;
    vertical-align: top;
    color: get-color("grey-600");
  }
}

.TransactionFee {
  margin-bottom: $spacing-lg;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: get-color("grey-600");
}

.Button {
  margin: $spacing-lg 0;
}

.AdditionalRules {
  margin-bottom: $spacing-md;
  font-size: 12px;
  color: get-color("grey-600");
}
