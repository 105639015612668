@import "scss/variablesV2";

.StatusChip {
  color: get-color("white");
  font-weight: 500;
  :global(.MuiChip-icon) {
    color: currentColor;
  }

  &.isSuccess { background-color: get-color("success-300"); }
  &.isWarning { background-color: get-color("warning-400"); }
  &.isError { background-color: get-color("error-400"); }
  &.isGhosted { opacity: 0.75; }
}
