@import "scss/variables";

.SubscriptionPlans {
  align-items: stretch;
}

.Plan {
  margin-top: 16px;
  height: 100%;

  &.featured {
    @include lg {
      height: calc(100% + 32px);
      margin-top: 0;
    }
  }
}

.More {
  margin-top: $spacing-lg;
}
