@import "scss/variables";

.OrderShippingAddressForm {
    padding: 12px 0;  // offset grid negative margin
}

.NonFieldError {
    margin-bottom: 24px;
}

.SubmitInput {
    display: none;
}
