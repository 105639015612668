@import "scss/variablesV3";

.ColorSwatch {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: $spacing-xs;
  border-radius: $border-radius-default;
  border: $border-divider;

  &.isUnknown {
    background: linear-gradient(180deg, #4B4AC6 0%, #2BC9A3 38.02%, #BCBE46 63.02%, #D2731A 100%) !important;
  }
}

.ColorSwatches {
  font-size: $font-size-body2;
  height: 28px;

  :global(.MuiTypography-root) {
    margin-left: auto;
  }

  &.isSmall {
    font-size: $font-size-body3;
    height: 20px;

    & > .ColorSwatch {
      width: 16px;
      height: 16px;
    }
  }


}
