@import "scss/variables";

.ReactAppWrapper {
  display: block;
  border: none;
  width: 100%;
  height: calc(100vh - 65px);

  @include md {
    height: 100vh;
  }
}
