@import "scss/variables";

.Switcher {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $spacing-lg * 2;
}

.Intro {
  margin-top: $spacing-md;
}

.ErrorCode {
  margin-top: $spacing-md;
  color: get-color("error-700");
  font-weight: 700;
}

.ErrorCode + .ErrorCode {
  margin-left: $spacing-sm;
}

.Plans {
  margin-top: $spacing-lg * 2;
}
